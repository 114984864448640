import {selectionStore} from "./selection.store";
import {config} from "../config";
import {AvailableCommercials, Commercial, SelectionSteps} from "../types";
import {buildRouteWithParams} from "../helpers/build-route-with-params";

export interface NavigationStoreItem {
    text: string;
    route: string;
    icon: NavigationHistoryIcons;
    params?:{[key:string]:any}
    commercialType?: AvailableCommercials;
}

export enum NavigationHistoryIcons {
    guests = "guests",
    date = "date",
    time = "time",
    menu = "menu",
    event = "event",
    offer = "offer"
}

export enum MainNavigationRoutes {
    details = "details",
    confirmation = "confirmation"
}

class NavigationStore {
    public history: Array<NavigationStoreItem>;
    public listeners: Array<() => void>;
    public mainNavigationListeners: { details: Array<() => void>, confirmation: Array<() => void> };
    public navigationRoutesState: { details: boolean, confirmation: boolean };
    private _currentRoute: string = "";

    constructor() {
        this.history = [];
        this.listeners = [];
        this.mainNavigationListeners = {
            details: [],
            confirmation: []
        };
        this.navigationRoutesState = {
            confirmation: false,
            details: false
        };
    }

    public resetNavigation(){
        selectionStore.clearSelection(SelectionSteps.guests);
        navigationStore.history = navigationStore.history.slice(0, 0);
        this.callListeners();
    }

    public addToHistory = (item: NavigationStoreItem, selectionStep: SelectionSteps) => {
        const itemIndex = navigationStore.history.findIndex((historyItem) => {
            return historyItem.route === item.route;
        });
        if (itemIndex !== -1) {
            selectionStore.clearSelection(selectionStep);
            navigationStore.history = navigationStore.history.slice(0, itemIndex);
        }
        this.history.push(item);
        this.callListeners();
    };

    public addToHistoryWithoutClearingSelection = (item: NavigationStoreItem) => {
        const itemIndex = navigationStore.history.findIndex((historyItem) => {
            return historyItem.route === item.route;
        });
        if (itemIndex === -1) {
            this.history.push(item);
            this.callListeners();
        }
    };

    public addGuestsToHistory = (guestTranslationKey: string) => {
        const navigationItem: NavigationStoreItem = {
            text: guestTranslationKey,
            params: {pax: selectionStore.guests},
            route: buildRouteWithParams(config.routes.selectionGuests),
            icon: NavigationHistoryIcons.guests
        };
        this.addToHistory(navigationItem, SelectionSteps.guests);
    };

    public addDateToHistory = () => {
        const navigationItem: NavigationStoreItem = {
            text: "",
            params: {date: selectionStore.date},
            route: buildRouteWithParams(config.routes.selectionCalendar),
            icon: NavigationHistoryIcons.date
        };
        this.addToHistory(navigationItem, SelectionSteps.date);
    };

    public addTimeToHistory = () => {
        const itemToAdd: NavigationStoreItem = {
            text: "",
            params: {time: selectionStore.time},
            route: buildRouteWithParams(config.routes.selectionTime),
            icon: NavigationHistoryIcons.time
        };
        this.addToHistory(itemToAdd, SelectionSteps.time);
    };

    public addEventsViewedToHistory = () => {
        let itemToAdd: NavigationStoreItem = {
            text: "no_event_selected",
            icon: NavigationHistoryIcons.event,
            route: `${buildRouteWithParams(config.routes.selectionCommercials)}/${AvailableCommercials.menus}`,
            commercialType: AvailableCommercials.menus
        };
        this.addToHistoryWithoutClearingSelection(itemToAdd);
    };

    public addCommercialSkippedToHistory = (commercialType: AvailableCommercials) => {
        const noMenuTranslation = "instabook.menus.no_menu";
        const noOfferTranslation = "instabook.offers.no_offer";
        let itemToAdd: any;
        switch (commercialType) {
            case AvailableCommercials.menus: {
                itemToAdd = {
                    text: noMenuTranslation,
                    icon: NavigationHistoryIcons.menu,
                };
                break;
            }
            default: {
                itemToAdd = {
                    text: noOfferTranslation,
                    icon: NavigationHistoryIcons.offer,
                };
            }
        }
        itemToAdd.route = `${buildRouteWithParams(config.routes.selectionCommercials)}/${commercialType}`;
        itemToAdd.commercialType = commercialType;
        this.addToHistory(itemToAdd as NavigationStoreItem, SelectionSteps.menu);
    };

    public addMenuToHistory = (commercial: Commercial, menuCount: number) => {
        const commercialType = AvailableCommercials.menus;
        const itemToAdd: NavigationStoreItem = {
            text: "",
            params: {menus: `${menuCount} ${commercial.name}`},
            route: `${buildRouteWithParams(config.routes.selectionCommercials)}/${commercialType}`,
            icon: NavigationHistoryIcons.menu,
            commercialType
        };
        this.addToHistory(itemToAdd, SelectionSteps.menu);
    };

    public addOfferToHistory = (commercial: Commercial) => {
        const commercialType = AvailableCommercials.offers;
        const itemToAdd: NavigationStoreItem = {
            text: commercial.name,
            route: `${buildRouteWithParams(config.routes.selectionCommercials)}/${commercialType}`,
            icon: NavigationHistoryIcons.menu,
            commercialType
        };
        this.addToHistory(itemToAdd, SelectionSteps.offer);
    };

    public removeCommercialFromHistory(commercialType: AvailableCommercials) {
        let selectionStep;
        switch (commercialType) {
            case AvailableCommercials.menus: {
                selectionStep = SelectionSteps.menu;
                break;
            }
            case AvailableCommercials.offers: {
                selectionStep = SelectionSteps.offer;
                break;
            }
        }

        const itemIndex = navigationStore.history.findIndex((historyItem) => {
            return historyItem.commercialType === commercialType;
        });
        if (itemIndex !== -1) {
            selectionStore.clearSelection(selectionStep);
            navigationStore.history = navigationStore.history.slice(0, itemIndex);
        }
        this.callListeners();
    }

    public registerListener = (listener: () => void) => {
        this.listeners.push(listener);
    };

    public unregisterListeners = () => {
        this.listeners = [];
    };

    public callListeners = () => {
        this.listeners.forEach((listener) => {
            listener();
        });
    }

    public registerMainNavigationListeners = (key: MainNavigationRoutes, listener: () => void) => {
        this.mainNavigationListeners[key].push(listener);
    };

    public changeMainNavigationRouteState = (key: MainNavigationRoutes, newValue: boolean) => {
        this.navigationRoutesState[key] = newValue;
        this.mainNavigationListeners[key].forEach((listener: () => void) => {
            listener();
        });
    };

    public getNavigationRoutesState = (key: MainNavigationRoutes) => {
        return this.navigationRoutesState[key];
    };

    public set currentRoute(newRoute: string) {
        this._currentRoute = newRoute;
    }

    public goBackRoute = (): string => {
        let indexOfCurrentRoute = this.history.findIndex((historyItem: NavigationStoreItem) => historyItem.route === this._currentRoute);
        if (indexOfCurrentRoute === 0) {
            return "#";
        }
        if (indexOfCurrentRoute === -1) {
            indexOfCurrentRoute = this.history.length;
        }
        return this.history[indexOfCurrentRoute - 1].route;
    };
}

export const navigationStore = new NavigationStore();
