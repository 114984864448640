import i18n from "../i18n";
import {moduleSettings} from "../stores/module-settings.store";
import { Area, AreaTranslation } from "../types";


export class AreaService{
    area:Area;
    constructor(area:Area){
        this.area = area
    }
    getTranslation():AreaTranslation|undefined{
        let displayedLanguageArea = undefined;

        if (this.area.translations){
            displayedLanguageArea = this.getNameFromI18nLanguage();
    
            if (!displayedLanguageArea) {
                displayedLanguageArea = this.getNameFromDefaultRestaurantLocale();
            }
    
            if(!displayedLanguageArea){
                displayedLanguageArea = this.loadFirstTranslation()
            }
        }
       
        return displayedLanguageArea;
    }

    private loadFirstTranslation() : AreaTranslation|undefined {
        return this.area.translations[0];
    }

    private getNameFromI18nLanguage(): AreaTranslation|undefined {
        return this.area.translations.find((translation: AreaTranslation) => translation.locale === i18n.language)
    }

    private getNameFromDefaultRestaurantLocale(): AreaTranslation|undefined {
        return this.area.translations.find((translation: AreaTranslation) => translation.locale === moduleSettings.getDefaultLocale());
    }
}