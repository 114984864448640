import React, { memo } from "react";
import styled from 'styled-components';

interface IPropertyProps {
    name: string,
    value: string
}

const AppPropertiesWrapper = styled.div`	
	display: flex;	
	align-items: center;
    justify-content: center;
    padding-top: 50px;
    width: 100%;
`;

const AppPropertiesStyle = styled.div`	
	background-color: lightGrey;
	color: #000;	
	border-radius: 5px;
	padding: 15px 22px;	
    width: 50%;

    @media screen and (max-width: 768px) {
        width: 100%;
      }
`;

const PropertyStyle = styled.div`
    padding-top: 5px;
    padding-bottom: 5px;    
`;

const PropertyName = styled.span`
    padding: 10px;
    font-weight: bold;
`;


const Property = ({ name, value }: IPropertyProps) => {
    return (
        <PropertyStyle>
            <PropertyName>{`${name}: `}</PropertyName><span>{value}</span>
        </PropertyStyle>
    )
}

const AppPropertiesDef = () => {
    return (
        <AppPropertiesWrapper>
            <AppPropertiesStyle>
                <Property name="VERSION" value={process.env.REACT_APP_VERSION} />
                <Property name="IB" value={process.env.REACT_APP_IB_API_BASE_URL} />
            </AppPropertiesStyle>
        </AppPropertiesWrapper>);
}

export const AppProperties = memo(AppPropertiesDef);