import {Area, AreaTranslation, DropdownOption, EngineMode, Period, Periods} from "../types";
import {moduleSettings} from "../stores/module-settings.store";
import i18n from "../i18n";
import { AreaService } from "./area-service";

class FiltersService {
    public mapAreaToTranslation(areas: Array<Area>, selectedArea: string | null): Array<DropdownOption> | null {
        const areasTranslations: Array<{ id: number; translation: AreaTranslation }> = this.loadAreaTranslations(areas);
        const options = this.loadAreaDropdownOption(areasTranslations, selectedArea);
        if (!options.length) {
            return null;
        }
        return options
    };

    private loadAreaDropdownOption(areasTranslations:Array<{ id: number; translation: AreaTranslation }>, selectedArea: string | null):Array<DropdownOption>{
        return areasTranslations.map((translation: { id: number; translation: AreaTranslation }) => {
            const displayText = translation.translation.name;
            const value = translation.id;
            return {
                value: value.toString(),
                displayText,
                isSelected: value.toString() === selectedArea
            };
        });
    }

    private loadAreaTranslations(areas: Array<Area>):Array<{ id: number; translation: AreaTranslation }>{
        const areasTranslations: Array<{ id: number; translation: AreaTranslation }> = [];
        areas.forEach((area: Area) => {
            let displayedLanguageArea = new AreaService(area).getTranslation();
            if (displayedLanguageArea) {
             areasTranslations.push({id: area.id, translation: displayedLanguageArea});
            }
        });
        return areasTranslations;
    }

    public getFiltersFromPeriods(timePeriods: Array<Period>, engineMode?:string) {
        var areas: Array<any> = [];
        const periods: Array<Periods> = [];
        const canBuildArea = engineMode && engineMode === EngineMode.table
        timePeriods.forEach((timePeriod: Period) => {
            if (canBuildArea){
                const areaId = timePeriod.area_id;
                if (areaId && !areas.map((area: Area) => area.id).includes(areaId)) {
                    const area = moduleSettings.areas.find((area: Area) => area.id === areaId);
                    if (area) {
                        areas.push(area);
                    }
                }
            }
            const period = timePeriod.period;
            if (!periods.includes(period)) {
                periods.push(period);
            }
        });
        if(areas.length === 0 && canBuildArea){
            areas =  moduleSettings.areas
        }
        return {
            areas,
            periods
        };
    }
}

let filtersService: FiltersService;

export const getFiltersService = (): FiltersService => {
    if (!filtersService) {
        filtersService = new FiltersService();
    }
    return filtersService;
};
