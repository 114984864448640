import React, {memo} from "react";
import {ThemeManager} from "../../theme-manager/theme-manager";
import styled from "styled-components";
import {Logo} from "./Logo";
import {moduleSettings} from "../../stores/module-settings.store";

const activeTheme = ThemeManager.getTheme();
const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 40%;
  height: ${activeTheme.footerHeight};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;

  @media screen and (max-width: 780px) {
    position: inherit;
    width: 100%;
  };
`;


const PowerBy = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-bottom: 4px;
    }
`;

const poweredBy = <PowerBy>
  <span>Powered by</span>
  <Logo />
</PowerBy>

const FooterDef = () => {
    return <FooterWrapper>
        {moduleSettings.settings.show_powered_by ? poweredBy : ""}
    </FooterWrapper>;
};
export const Footer = memo(FooterDef);
