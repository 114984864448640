import { config } from "../config";
import { QueryParam } from "../types";
import { getRequestParamsService } from "../services/request-params.service";

const apiBase = config.baseUrl;

export class Endpoint {
    public base: string;
    public endPoint: string;
    public queryParams?: Array<QueryParam> | null;

    constructor(endPoint: string, base: string = apiBase, queryParams?: Array<QueryParam>) {
        this.base = base;
        this.endPoint = endPoint;
        this.queryParams = queryParams ?? null;
    }

    public setQueryParams(queryParams: Array<QueryParam>) {
        this.queryParams = queryParams;
    }

    public getEndpoint() {
        let endpoint = `${this.base}/${config.apiVersion}/${this.endPoint}`;
        if (this.queryParams && this.queryParams.length !== 0) {
            endpoint += "?" + this.queryParams.map((queryParam: QueryParam) => `${queryParam.queryParamName}=${queryParam.queryParamValue}`).join(("&"));
        }
        return endpoint;
    }

    public getHeaders() {
        const requestParamsService = getRequestParamsService();
        const { referralKey } = requestParamsService.getParams();
        const headers:{ [key: string]: string } = {
            "X-Token": requestParamsService.getModuleToken(),
            "Content-Type": "application/json"
        };
        if (referralKey !== undefined) {
            headers["Referral-Key"] = referralKey
        };
        return headers;
    }
}
