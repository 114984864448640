import React, { memo } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { config } from "../../config";
import { moduleSettings } from "../../stores/module-settings.store";
import { SelectionLayout } from "../selection/selection-layout/selection-layout";
import { DetailsLayout } from "../details/details-layout/details-layout";
import { ConfirmationRouter } from "../confirmation/confirmation-router/confirmation-router";
import { buildRouteWithParams } from "../../helpers/build-route-with-params";
import { HeroImage } from "../hero-image/hero-image";
import { ThemeManager } from "../../theme-manager/theme-manager";
import { ErrorBoundary } from 'react-error-boundary'
import { ApiErrorCode } from "../../services/api-errors";
import { selectionStore } from "../../stores/selection.store";
import { navigationStore } from "../../stores/navigation.store";
import { useTranslation } from "react-i18next";
import { InformationText } from "../information-text/information-text";

const activeTheme = ThemeManager.getTheme();

const headerAndFooterHeight = parseInt(activeTheme.headerHeight, 0) + parseInt(activeTheme.footerHeight, 0);

const MainContentFlex = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  min-height: calc(100vh - ${headerAndFooterHeight}px - 35px);
  max-width: 100%;
  background-color: ${activeTheme.backgroundColor};
  color: ${activeTheme.textColor};
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
`;

const MainContentLayout = styled.div`
  padding: 0 90px;
  width: calc(100% - 380px);

  @media screen and (max-width: 980px) {
    padding: 0 30px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
  `;

const MainContentDef = () => {
  const { t } = useTranslation();
  const onlineBookingEnabled = moduleSettings.online_booking_enabled;
  const restaurantImageUrl = moduleSettings.getRestaurantImageUrl();
  const hasServices = moduleSettings.periods.length > 0;

  const ErrorFallback = ({error, resetErrorBoundary}) => {
    if (error.code === ApiErrorCode.pending_booking_unfound){
      selectionStore.resetStore();
      navigationStore.resetNavigation();
    }

    resetErrorBoundary();
    return <Redirect to={{
      pathname: error.getRedirectPath(),
      state: { error: error.getMessage(), errorType: error.getErrorType(), errorDetails: error.data }
    }}/>;
  }

  const mainContent =  !onlineBookingEnabled || !hasServices ? <InformationText key={"no_online_booking_enabled_msg"} text={t("instabook.errors.no_access")} status={"warning"}/>  :
    <Switch>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
        }}>
        <Route path={buildRouteWithParams(config.routes.selection)}>
          <SelectionLayout/>
        </Route>
        <Route path={buildRouteWithParams(config.routes.details)}>
          <DetailsLayout/>
        </Route>
        <Route path={buildRouteWithParams(config.routes.confirmation)}>
          <ConfirmationRouter/>
        </Route>
      </ErrorBoundary>
    </Switch>

  return <MainContentFlex>
    <Router>
      <MainContentWrapper>
        <Redirect
        to={buildRouteWithParams(config.routes.selection)}/>
        <MainContentLayout>
          {mainContent}
        </MainContentLayout>
        <HeroImage restaurantImageUrl={restaurantImageUrl}/>
      </MainContentWrapper>
    </Router>
  </MainContentFlex>;
};
export const MainContent = memo(MainContentDef);
