import {getHttpService} from "../services/http.service";
import {SettingsEndpoint} from "../endpoints/SettingsEndpoint";
import {Observable} from "rxjs";

const httpService = getHttpService();
const hasCommercialFirstStep = false;
const commercialsFirstStep =
    "menus";
// "offers";
// "events";

export const getModuleSettings = (restaurantId?: string, bookingId?: string, bookingToken?: string): Observable<any> => {
    const endpoint = new SettingsEndpoint();
    var queryParams = [];
    if (restaurantId !== undefined && /^[0-9]+$/.test(restaurantId)) {
        queryParams.push({
            queryParamName: "restaurant_id",
            queryParamValue: restaurantId
        });
    }
    if (hasCommercialFirstStep) {
        queryParams.push({
            queryParamName: "first_step",
            queryParamValue: commercialsFirstStep
        });
    }
    if (bookingId !== undefined && bookingToken !== undefined) {
        queryParams.push({
            queryParamName: "booking_id",
            queryParamValue: bookingId
        });
        queryParams.push({
            queryParamName: "booking_token",
            queryParamValue: bookingToken
        });
    }
    endpoint.setQueryParams(queryParams);
    return httpService.get(endpoint);
};
