import { Endpoint } from "./BaseEndpoint"
import { config } from "../config";
import { QueryParam } from "../types";
import { getRequestParamsService } from "../services/request-params.service";

const apiBase = config.baseUrl;
export class WithRestaurantIdEndpoint extends Endpoint{
    constructor(endPoint: string, base: string = apiBase, queryParams?: Array<QueryParam>) {
        super(endPoint, base, queryParams)
    }

    public addQueryParams(queryParams: Array<QueryParam>) {
        const requestParamsService = getRequestParamsService();
        const { restaurantId } = requestParamsService.getParams();
        if(restaurantId){
            let queryParamName = queryParams.map((queryParams) => queryParams.queryParamName)
            if (!queryParamName.includes("restaurant_id")){
                queryParams.push({
                    queryParamName: "restaurant_id",
                    queryParamValue: restaurantId
                });
            } 
        };
        this.setQueryParams(queryParams);
    }
}