import {BookingState, PendingBooking, PendingBookingContact, Prepayment} from "../types";
import {loadStripe} from "@stripe/stripe-js/pure";
import { Stripe } from "@stripe/stripe-js";
import i18n from "../i18n";
import { numberFormat } from "../utils";

class PendingBookingStore {
    private _pendingBooking: PendingBooking = {} as PendingBooking;
    private isPrepaymentSet: boolean = false;
    private _stripePromise: PromiseLike<Stripe | null> | undefined;

    set pendingBooking(newPendingBooking: PendingBooking) {
        if(newPendingBooking.prepayment) {
           this.setupStripePromise(newPendingBooking);
        }
        this._pendingBooking = newPendingBooking;
    }

    setupStripePromise(newPendingBooking: PendingBooking) {
        let prepayment = newPendingBooking.prepayment;
        let stripeAccountInfo = {
            stripeAccount: prepayment.stripe_account
        };
        let stripeCons : PromiseLike<Stripe | null>;
        if (prepayment.stripe_account !== null){
            stripeCons = loadStripe(newPendingBooking.prepayment.stripe_key, stripeAccountInfo);
        }else{
            stripeCons = loadStripe(newPendingBooking.prepayment.stripe_key);
        }
        if(!this.isPrepaymentSet) {
            this.isPrepaymentSet = true;
            this.stripePromise = stripeCons;
        } else {
            if(this._pendingBooking.prepayment.stripe_key !== newPendingBooking.prepayment.stripe_key) {
                this.stripePromise = stripeCons
            }
        }
    }

    get stripePromise() : PromiseLike<Stripe | null> | undefined {
        return this._stripePromise;
    }

    set stripePromise(stripePL: PromiseLike<Stripe | null> | undefined){
        this._stripePromise = stripePL;
    }

    get pendingBooking(): PendingBooking {
        return this._pendingBooking;
    }

    get pendingBookingContact(): PendingBookingContact {
        return this._pendingBooking.contact_info;
    }

    set pendingBookingContact(contactInfo:PendingBookingContact) {
        this._pendingBooking.contact_info = contactInfo;
    }

    public getPendingBookingMessage(lang: string): string | null {
        const note = this._pendingBooking.bookings_detail?.note ?? null;
        for (let key in note) {
            if (note.hasOwnProperty(key)) {
            }
        }
        return note && note[lang] ? note[lang] : null;
    }

    public getPendingBookingPrepayment(): Prepayment {
        return this._pendingBooking.prepayment;
    }

    public getPendingBookingPrepaymentKind(): string {
        let paymentKind = this.getPendingBookingPrepayment().payment_kind ;
        return paymentKind === "guarantee" ? "deposit" : "down_payment";
    }

    public getPendingBookingPrepaymentCurrency(): string {
        return this.getPendingBookingPrepayment().currency;
    }

    public getPrepaymentPriceWithPrecision(): number | undefined{
        let amount = this.getPendingBookingPrepayment().amount;
        if (amount){
            return (amount/100)
        }
    }

    public getIsVoucherEnabled(): boolean {
        return this._pendingBooking.voucher_enabled;
    }

    public getBookingState(): BookingState {
        return this._pendingBooking.booking_state;
    }

    public getPendingBookingPrepaymentInfo():string|undefined {
        let priceWithPrecision = this.getPrepaymentPriceWithPrecision();
        if (priceWithPrecision){
            let priceAndCurrency = numberFormat(i18n.language, priceWithPrecision, this.getPendingBookingPrepaymentCurrency())
            return i18n.t(`instabook.bookings_detail.prepayment_infos.${this.getPendingBookingPrepaymentKind()}`, {price_and_currency: priceAndCurrency})
        }
    }
}

export const pendingBookingStore = new PendingBookingStore();
