import {DropdownOption} from "../../types";
import i18n from "../../i18n";

export const stringArrayToDropdown = (valueArr: Array<string>, selectedValue?: string | null, prefix?: string | null): Array<DropdownOption> => {
    return  valueArr.map((value: string) => stringToDropdown(value, selectedValue, prefix));
};

export const stringToDropdown = (value: string, selectedValue?: string | null, prefix?: string | null): DropdownOption => {
    return {
        value,
        displayText: i18n.t(`instabook.${prefix ? prefix : ""}${value}`),
        isSelected: selectedValue === value
    }
}
