/// <reference path='./global.d.ts'/>

import React from "react";
import { MainLayout } from "./components/main-layout/main-layout";
import { AppProperties } from "./components/app-properties/app-properties";
import "./i18n";
import "./App.scss";
import styled from "styled-components";
import { ThemeManager } from "./theme-manager/theme-manager";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { config } from "./config";
import { RestaurantError } from "./components/errors/restaurant-error/restaurant-error";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

const activeTheme = ThemeManager.getTheme();

const AppStyle = styled.div`
  color: ${activeTheme.textColor};
  background-color: ${activeTheme.backgroundColor};
`;

const muiTheme = createMuiTheme({
    palette: {
        secondary: {
            main: `${activeTheme.primaryColor}`
        }
    }
});

function App() {
    return <MuiThemeProvider theme={muiTheme}>
        <AppStyle>
            <Router>
                <Switch>
                    <Route path="/__version__">
                        <AppProperties />
                    </Route>
                    <Route path={config.routes.url}>
                        <MainLayout />
                    </Route>
                    <Route>
                        <RestaurantError />
                    </Route>
                </Switch>
            </Router>
        </AppStyle>
    </MuiThemeProvider>;
}

export default App;
