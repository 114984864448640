import React, {memo} from "react";
import {CommercialTitle} from "../commercial-title";
import {CommercialText} from "../commercial-text";
import {Commercial} from "../../../types";
import styled from "styled-components";

interface ICommercialsOfferItemProps {
    commercial: Commercial
}

const CommercialOffer = styled.div`
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 12px 24px;
  margin-bottom: 22px;
  cursor: pointer;
`;

const Item = (props: ICommercialsOfferItemProps) => {
    return <CommercialOffer>
            <CommercialTitle title={props.commercial.name}/>
            <CommercialText text={props.commercial.description}/>
        </CommercialOffer>
};

export const CommercialsOfferItem = memo(Item);
