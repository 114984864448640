import React, {memo} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {ThemeManager} from "../../../theme-manager/theme-manager";

const activeTheme = ThemeManager.getTheme();

interface INavigationItem {
    icon: any;
    text?: string;
    path: string;
}

const NavigationItemWrapper = styled(Link)`
  display: flex;
  justify-content: space-between;
  color: ${activeTheme.textColorGray};
  font-weight: bold;
  font-size: 14px;
  border-right: 1px solid ${activeTheme.textColorGray};
  padding: 0 10px;
  text-decoration: none;
  align-items: center;

  &:last-child {
    border-right: 1px solid transparent;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const NavigationItemText = styled.span`
  display: inline-block;
  margin-left: 8px;
`;

const Item = (props: INavigationItem) => {
    return <NavigationItemWrapper to={props.path}>
        {props.icon}
        {props.text ? <NavigationItemText>{props.text}</NavigationItemText> : ""}
    </NavigationItemWrapper>;
};

export const NavigationItem = memo(Item);
