import { IThemeProps, HeaderStyle, ButtonStyle } from "../types";
import { assignProps } from "../utils";

const blueGol: string = "#20b8cf";
const orange: string = "#e7601c";
const greenDark: string = "#2fcc95";
// const greenLight: string = "#9ac328";
// const yellow: string = "#ffcc33";
// const black: string = "#333333";
const black: string = "#646470";  // kei
const gray: string = "#888888";
const grayLight: string = "#F8F8F8";
const grayMedium: string = "#bbbbbb";
const white: string = "#ffffff";
const error: string = "#f44336";

export class Theme {
    public primaryColor: string = blueGol;
    public secondaryColor: string = orange;
    public backgroundColor: string = white;
    public headerBackgroundColor: string = blueGol;
    public headerStyle: HeaderStyle = HeaderStyle.contained;
    public invertFontColor: string = white;
    public textColor: string = black;
    public textColorGray: string = gray;
    public disabledColor: string = grayMedium;
    public lineSeparatorStyle: string = `0.5px solid ${grayMedium}`;
    public headerHeight: string = "50px";
    public footerHeight: string = "65px";
    public informationTextBackgroundColor = grayLight;
    public legendBorderOrange: string = orange;
    public legendBorderGray: string = gray;
    public reservationSuccess: string = greenDark;
    public buttonStyle: ButtonStyle = ButtonStyle.text;
    public error: string = error;

    public globalPadding: string = "10px";

    constructor(props?: IThemeProps) {
        if (props) {
            assignProps(this, props);
        }
    }
}