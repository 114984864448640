import React, {memo} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {Dropdown} from "../../dropdown/dropdown";
import {selectionStore} from "../../../stores/selection.store";
import {stringArrayToDropdown, stringToDropdown} from "../../dropdown/dropdown-transform";
import {getFiltersService} from "../../../services/filters.service";
import {moduleSettings} from "../../../stores/module-settings.store";
import {getSelectionStoreChangesService} from "../../../services/selection-store-changes.service";

interface ICalendarFilters {
    alignPosition: CalendarFiltersPosition
}

export enum CalendarFiltersPosition {
    start = "start",
    center = "center"
}

const FilterStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: ${props => props.theme.alignPosition};
  align-items: center;
  text-align: center;
  margin-bottom: 30px;

  @media screen and (min-width: 981px),
  (min-width: 481px) and (max-width: 768px) {
    > * {
      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }

  @media screen and (max-width: 980px) and (min-width: 769px),
  (max-width: 480px) {
    > * {
      flex: 0 0 100%;
      margin-bottom: 10px;
    }
  }
`;

const Filters = (props: ICalendarFilters) => {
    // eslint-disable-next-line
    const {t} = useTranslation();

    const periodChanged = (value: any) => {
        if (selectionStore.period !== value) {
            selectionStore.period = value;
            getSelectionStoreChangesService().callListeners("period");
        }
    };

    const areaChanged = (value: any) => {
        if (selectionStore.area !== value) {
            selectionStore.area = value;
            getSelectionStoreChangesService().callListeners("area");
        }
    };

    const periodsOptions = stringArrayToDropdown(moduleSettings.periods, selectionStore.period, "periods.");
    const areasOptions = getFiltersService().mapAreaToTranslation(moduleSettings.areas, selectionStore.area);

    return <FilterStyle theme={props}>
        <Dropdown
            options={periodsOptions}
            placeholderOption={stringToDropdown("all", null, "periods.")}
            placeholderSelectable={true}
            onItemClick={periodChanged}
        />
        {areasOptions ? <Dropdown
            options={areasOptions}
            placeholderOption={stringToDropdown("all", null, "rooms.")}
            placeholderSelectable={true}
            onItemClick={areaChanged}
        /> : ""}
    </FilterStyle>;
};

export const CalendarFilters = memo(Filters);
