export const config = {
  baseUrl: process.env.REACT_APP_IB_API_BASE_URL ?? "",
  token: process.env.REACT_APP_IB_TOKEN ?? "",
  apiVersion: "v2",
  baseImageUrl: "https://s3-eu-west-1.amazonaws.com/gol-data-fr-ireland/instabook/",
  routes: {
    base: "/instabook/bookings",
    url: [
      "/instabook/bookings/:bookingId(\\d+)/update_from_email",
      "/instabook/bookings/:version(v3)?/:restaurantId(\\d+)/:referralKey",
      "/instabook/bookings/:version(v3)?/:referralKey"
    ],
    selection: "selection",
    details: "details",
    confirmation: "confirmation",
    selectionGuests: "selection/guests",
    selectionCalendar: "selection/calendar",
    selectionTime: "selection/time",
    selectionCommercials: "selection/commercials",
    confirmationPayment: "confirmation/payment",
    confirmationEnd: "confirmation/end"
  },
  default_language: "en"
};