import React, {memo} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import PaymentIcon from "@material-ui/icons/Payment";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import {ThemeManager} from "../../../theme-manager/theme-manager";
import PauseIcon from "@material-ui/icons/Pause";

const activeTheme = ThemeManager.getTheme();

const LegendStyle = styled.div`
  display: flex;
  flex: 0 0 100%;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;

const LegendItemsStyle = styled.div`
  display: flex;
  padding: 10px 15px;
  border: 1px solid ${activeTheme.informationTextBackgroundColor};
  border-radius: 5px;
`;

const LegendItem = styled.div`
  display: flex;
  margin: 0 10px;
  justify-content: center;
  align-items: center;
`;

const RoundedIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-color: ${activeTheme.disabledColor};
  color: white;
  margin-right: 5px;

  &.colored {
    background-color: ${activeTheme.secondaryColor};
  }
  
  svg {
    width: 12px;
    height: 12px;
  }
`;

const Legend = () => {
    const {t} = useTranslation();
    return <LegendStyle>
        <LegendItemsStyle>
            <LegendItem>
                <RoundedIcon className={"colored"}>
                    <PauseIcon/>
                </RoundedIcon>
                <div>
                    {t("instabook.legend.legend_waiting")}
                </div>
            </LegendItem>
            <LegendItem>
                <RoundedIcon>
                    <FlashOnIcon/>
                </RoundedIcon>
                <div>
                    {t("instabook.legend.legend_event")}
                </div>
            </LegendItem>
            <LegendItem>
                <RoundedIcon>
                    <PaymentIcon/>
                </RoundedIcon>
                <div>
                    {t("instabook.legend.legend_prepay")}
                </div>
            </LegendItem>
        </LegendItemsStyle>
    </LegendStyle>;
};
export const TimeSlotLegend = memo(Legend);
