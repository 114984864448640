import {getHttpService} from "../services/http.service";
import {
    CommitPendingBooking,
    PendingBookingAddMenuEndpoint,
    PendingBookingValidateGiftCodeEndpoint,
    PendingBookingEndpoint,
    PendingBookingRemoveMenuEndpoint,
    UpdatePendingBookingEndpoint,
    UpdatePendingBookingFromBookingEndPoint
} from "../endpoints/PendingBooking";
import {InitialPendingBookingRequest, PendingBookingContact} from "../types";
import {Observable} from "rxjs";
import {pendingBookingStore} from "../stores/pending-booking.store";

const httpService = getHttpService();

export const createInitialPendingBookingRequest = (pendingBooking: InitialPendingBookingRequest, bookingId?: string, bookingToken?: string): Observable<any> => {
    let endpoint = null;
    pendingBooking.booking_token = bookingToken;
    if (bookingId && bookingToken) {
        endpoint = new UpdatePendingBookingFromBookingEndPoint(bookingId);
    } else {
        endpoint = new PendingBookingEndpoint();
    }
    return httpService.post(endpoint, pendingBooking);
};


export const updatePendingBookingRequest = (data: {contact_info?: PendingBookingContact, selected_offer?: number}): Observable<any> => {
    const id = pendingBookingStore.pendingBooking.id;
    const endpoint = new UpdatePendingBookingEndpoint(id);
    return httpService.put(endpoint, data);
}

export const cancelPendingBookingRequest = (): Observable<any> => {
    const id = pendingBookingStore.pendingBooking.id;
    const endpoint = new UpdatePendingBookingEndpoint(id);
    return httpService.delete(endpoint, {});
}

export const checkCouponCode = (gift_code: string): Observable<any> => {
    const id = pendingBookingStore.pendingBooking.id;
    const endpoint = new PendingBookingValidateGiftCodeEndpoint(id);
    const voucher_infos = {gift_code};
    return httpService.put(endpoint, voucher_infos);
}

export const addCouponCodeToPendingBooking = (code: string): Observable<any> => {
    const id = pendingBookingStore.pendingBooking.id;
    const endpoint = new UpdatePendingBookingEndpoint(id);
    const gift_code = {code};
    return httpService.put(endpoint, gift_code);
}

export const addMenuToPendingBooking = (menu_id: number, quantity: number): Observable<any> => {
    const id = pendingBookingStore.pendingBooking.id;
    const endpoint = new PendingBookingAddMenuEndpoint(id);
    return httpService.put(endpoint, {menu_id, quantity});
}

export const removeMenuFromPendingBooking = (menu_id: number): Observable<any> => {
    const id = pendingBookingStore.pendingBooking.id;
    const endpoint = new PendingBookingRemoveMenuEndpoint(id);
    return httpService.put(endpoint, {menu_id});
}

export const commitPendingBooking = (data: {stripe_token?: string}): Observable<any> => {
    const id = pendingBookingStore.pendingBooking.id;
    const endpoint = new CommitPendingBooking(id);
    return httpService.put(endpoint, data);
}
