import React, {memo, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {navigationStore} from "../../../stores/navigation.store";
import {Dropdown} from "../../dropdown/dropdown";
import styled from "styled-components";
import {ThemeManager} from "../../../theme-manager/theme-manager";
import {Redirect, useLocation} from "react-router-dom";
import {config} from "../../../config";
import {selectionStore} from "../../../stores/selection.store";
import {moduleSettings} from "../../../stores/module-settings.store";
import {DropdownOption} from "../../../types";
import GroupIcon from "@material-ui/icons/Group";
import {buildRouteWithParams} from "../../../helpers/build-route-with-params";
import {getTagManagerService} from "../../../services/tag-manager.service";
import {AcceptedPositions, ContinueButton} from "../../continue-button/continue-button";
import { ErrorMessage } from "../../errors/message-error-view";
import { ApiErrorCode } from "../../../services/api-errors/error-types";

const activeTheme = ThemeManager.getTheme();

const DropdownWrapper = styled.div`
  display: flex;
  padding: ${activeTheme.globalPadding} 0 0;
  align-items: center;
`;

const SelectionGuestsTitle = styled.div`
  font-weight: bold;
`;

export interface ISelectionGuest {
    isFirstStep?: boolean;
}

const Guests = (props: ISelectionGuest) => {
    const {t} = useTranslation();
    const [redirect, setRedirect] = useState(false);
    const [error,] = useState(useLocation().state);

    useEffect(() => {
        navigationStore.currentRoute = buildRouteWithParams(config.routes.selectionGuests);
    }, []);
    if (redirect) {
        return <Redirect to={buildRouteWithParams(config.routes.selectionCalendar)}/>;
    }
    const guestsCapacity = moduleSettings.getCapacity();
    const options: Array<DropdownOption> = [];
    let selectedValue = !guestsCapacity.minGuests ? 2 : guestsCapacity.minGuests;
    const restaurantId = moduleSettings.getRestaurantId();
    getTagManagerService(restaurantId).pushTags("pax", {first_step: props.isFirstStep});
    if (selectionStore.guests) {
        selectedValue = selectionStore.guests;
    }
    for (let i = guestsCapacity.minGuests; i <= guestsCapacity.maxGuests; i++) {
        options.push({
            value: `${i}`,
            displayText: `${i}`,
            isSelected: i === selectedValue
        });
    }
    const addToNavigationAndRedirect = (value: any): void => {
        selectionStore.guests = Number(value);
        navigationStore.addGuestsToHistory(selectionStore.guests === 1 ? "instabook.common.person_pax" : "instabook.common.people_pax");
        setRedirect(true);
    };

    const addToNavigation = () => {
        selectionStore.guests = Number(selectedValue);
        navigationStore.addGuestsToHistory(selectionStore.guests === 1 ? "instabook.common.person_pax" : "instabook.common.people_pax");
    }

    return <div>
        <SelectionGuestsTitle>
            {t("instabook.bookings.persons")}
        </SelectionGuestsTitle>
        {error && <ErrorMessage error={error["error"]} status={error["errorDetails"]["code"] === ApiErrorCode.pending_booking_unfound ? "warning" : "Error"} />}
        <DropdownWrapper>
            <Dropdown options={options.splice(1)}
                      placeholderOption={options[0]}
                      placeholderSelectable={true}
                      placeholderIcon={"icon-group"}
                      onItemClick={addToNavigationAndRedirect}
                      icon={<GroupIcon/>}
                      isGuestsSelection={true}
            />
        </DropdownWrapper>
        <ContinueButton onClick={addToNavigation}
                        buttonTextKey={"button.continue"}
                        position={AcceptedPositions.left}
                        route={buildRouteWithParams(config.routes.selectionCalendar)}/>
    </div>;
};
export const SelectionGuests = memo(Guests);
