import React, {memo, useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import {CheckoutForm} from "./checkout-form";
import {navigationStore} from "../../../stores/navigation.store";
import {buildRouteWithParams} from "../../../helpers/build-route-with-params";
import {config} from "../../../config";
// import {GeneralMessage} from "../../general-message/general-message";
import {PendingBookingMessage} from "../../pending-booking-message/pending-booking-message";
import {pendingBookingStore} from "../../../stores/pending-booking.store";
import {useTranslation} from "react-i18next";
import { StripeElementLocale, StripeElementsOptions } from "@stripe/stripe-js";

const Payment = () => {
    const [stripe, setStripePromise] = useState(pendingBookingStore.stripePromise)
    const {i18n} = useTranslation()
    useEffect(() => {
        navigationStore.currentRoute = buildRouteWithParams(config.routes.confirmation);
    }, []);
    const stripeOption : StripeElementsOptions= {
        locale: i18n.language as StripeElementLocale
    }
    /*/!\ changing locale while already rendering, leads to re-render component, hence lost card info"*/
    return <div>
        {/* <GeneralMessage/> */}
        <PendingBookingMessage/>
        {stripe && <Elements key={i18n.language} options={stripeOption} stripe={stripe}>
            <CheckoutForm/>
        </Elements>}
    </div>;
};

export const ConfirmationPayment = memo(Payment);
