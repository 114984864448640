
import React, {memo} from "react";
import styled from "styled-components";
import { ThemeManager } from "../../theme-manager/theme-manager";

interface IProps  {
  restaurantImageUrl: string;
}

const activeTheme = ThemeManager.getTheme();

const headerAndFooterHeight = parseInt(activeTheme.headerHeight, 0) + parseInt(activeTheme.footerHeight, 0);

const HeroImageWrapper = styled.div<IProps>`
  flex: 0 0 380px;
  max-width: 380px;
  max-height: calc(100vh - ${headerAndFooterHeight}px - 35px);
  background: url(${props => props.restaurantImageUrl}) no-repeat center right;
  background-size: contain;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const HeroImageDef = (props: IProps) => {
    return <HeroImageWrapper restaurantImageUrl={props.restaurantImageUrl}/>;
};
export const HeroImage = memo(HeroImageDef);
