import React, {memo} from "react";
import styled from "styled-components";
import {ThemeManager} from "../../theme-manager/theme-manager";

const activeTheme = ThemeManager.getTheme();

interface ICommercialTextProps {
    text: string;
}

const TextDisplay = styled.div`
	color: ${activeTheme.textColorGray};
`;

const Text = (props: ICommercialTextProps) => {
    return <TextDisplay>
        {props.text}
    </TextDisplay>
}

export const CommercialText = memo(Text);
