import {Endpoint} from "./BaseEndpoint";

export class PendingBookingEndpoint extends Endpoint {
    constructor() {
        super("pending_bookings");
    }
}

export class UpdatePendingBookingFromBookingEndPoint extends PendingBookingEndpoint {
    constructor(bookingId: string) {
        super();
        this.endPoint += `/update_from_booking/${bookingId}`;
    }
}

export class UpdatePendingBookingEndpoint extends PendingBookingEndpoint {
    constructor(pendingBookingId: string) {
        super();
        this.endPoint += `/${pendingBookingId}`;
    }
}

export class PendingBookingValidateGiftCodeEndpoint extends UpdatePendingBookingEndpoint {
    constructor(pendingBookingId: string) {
        super(pendingBookingId);
        this.endPoint += "/validate_gift_code";
    }
}

export class PendingBookingAddMenuEndpoint extends UpdatePendingBookingEndpoint {
    constructor(pendingBookingId: string) {
        super(pendingBookingId);
        this.endPoint += "/pick_menu";
    }
}

export class PendingBookingRemoveMenuEndpoint extends UpdatePendingBookingEndpoint {
    constructor(pendingBookingId: string) {
        super(pendingBookingId);
        this.endPoint += "/remove_menu";
    }
}

export class CommitPendingBooking extends UpdatePendingBookingEndpoint {
    constructor(pendingBookingId: string) {
        super(pendingBookingId);
        this.endPoint += "/commit";
    }
}
