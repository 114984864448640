import React, {memo, useEffect, useState} from "react";
import {AvailableCommercials, SelectionCommercial} from "../../../types";
import {SelectionCommercialsDisplay} from "../../selection/selection-commercials-display/selection-commercials-display";
import {selectionStore} from "../../../stores/selection.store";
import {getCommercialsService} from "../../../services/commercials.service";
import {useHistory, useLocation} from "react-router-dom";
import {config} from "../../../config";
import {MainNavigationRoutes, navigationStore} from "../../../stores/navigation.store";
import {moduleSettings} from "../../../stores/module-settings.store";
import {buildRouteWithParams} from "../../../helpers/build-route-with-params";
import { ErrorMessage } from "../../errors/message-error-view";

export interface ISelectionCommercials {
    isFirstStep?: boolean;
    firstStepCommercialCompleted?: () => void
    commercials: SelectionCommercial,
}

const Commercials = (props: ISelectionCommercials) => {
    const commercialsService = getCommercialsService();
    const location = useHistory();
    const setSelectedCommercial = () => {
        selectionStore.setSelectedCommercial(props.commercials.type, true);
    };
    const [error,] = useState(useLocation().state);

    useEffect(() => {
        navigationStore.currentRoute = buildRouteWithParams(`${config.routes.selectionCommercials}/${props.commercials.type}`);
    }, [props]);
    const commercialSelected = () => {
        setSelectedCommercial();
        getNextCommercial();
    };
    const eventsContinue = () => {
        getNextCommercial();
    };
    const selectionSkipped = () => {
        // if commercial is first step and is skipped
        // we should ask for that commercial again after selection
        selectionStore.clearCommercialStep(props.commercials.type);
        navigationStore.removeCommercialFromHistory(props.commercials.type);
        navigationStore.addCommercialSkippedToHistory(props.commercials.type);
        if (props.commercials.type === AvailableCommercials.menus) {
            selectionStore.menuCount = 0;
        }
        if (!props.isFirstStep) {
            setSelectedCommercial();
        }
        getNextCommercial(false);
    };
    const getNextCommercial = (isCommercialSelected: boolean = true) => {
        if (props.isFirstStep) {
            moduleSettings.firstStepCommercialCompleted(isCommercialSelected);
            if (props.firstStepCommercialCompleted) {
                props.firstStepCommercialCompleted();
            }
        } else {
            const nextStep = commercialsService.nextStepCommercial(props.commercials.type);
            if (nextStep) {
                location.push({
                    pathname: `${buildRouteWithParams(config.routes.selectionCommercials)}/${nextStep}`
                });
            } else {
                navigationStore.changeMainNavigationRouteState(MainNavigationRoutes.details, true);
                location.push({
                    pathname: `${buildRouteWithParams(config.routes.details)}/`
                });
            }
        }
    };
    return <div>
        {error && <ErrorMessage error={error["error"]} status={"error"}/>}
        
        <SelectionCommercialsDisplay
            selectionSkipped={selectionSkipped}
            commercialSelected={commercialSelected}
            eventsContinue={eventsContinue}
            commercials={props.commercials.commercials}
            commercialsKey={props.commercials.commercialsKey}
            commercialsType={props.commercials.type}
            isFirstStep={props.isFirstStep}
    /></div>;
};

export const SelectionCommercials = memo(Commercials);
