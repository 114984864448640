import React, {memo, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {
    NavigationHistoryIcons,
    navigationStore,
    NavigationStoreItem
} from "../../stores/navigation.store";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {NavigationItem} from "./navigation-item/navigation-item";
import TodayIcon from "@material-ui/icons/Today";
import GroupIcon from "@material-ui/icons/Group";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const EmptyNavigation = styled.div`
  margin-bottom: 35px;
`;

const NavigationWrapper = styled(EmptyNavigation)`
  display: flex;
  align-items: center;
  margin-top: 24px;
  flex: 0 0 100%;

  @media screen and (max-width: 980px) {
    padding: 0 30px;
  }

  @media screen and (max-width: 480px) {
    padding: 0 10px;
  }
`;

const NavigationItems = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ArrowBackWrapper = styled.div`
  padding: 10px 0;
`;

const getNavigationStoreItems = (): Array<JSX.Element> => {
    return navigationStore.history.map((navigationItem: NavigationStoreItem) => {
        let icon;
        let translatedText = i18n.t(navigationItem.text);
        switch (navigationItem.icon) {
            case NavigationHistoryIcons.guests:
                icon = <GroupIcon/>;
                translatedText = i18n.t(navigationItem.text, navigationItem.params);
                break;
            case NavigationHistoryIcons.date:
                icon = <TodayIcon/>;
                const {date} = navigationItem.params;
                translatedText = date.locale(i18n.language).format("D MMMM YYYY")
                break;
            case NavigationHistoryIcons.time:
                icon = <AccessTimeIcon/>;
                translatedText = navigationItem.params.time
                break;
            case NavigationHistoryIcons.event:
                icon = <FlashOnIcon/>;
                break;
            case NavigationHistoryIcons.menu:
                icon = <AssignmentTurnedInIcon/>;
                translatedText = i18n.t(navigationItem.text, navigationItem.params?.menus);
                break;
            case NavigationHistoryIcons.offer:
                icon = <LocalOfferIcon/>;
                break;
            default:
                icon = <TodayIcon/>;
                translatedText = "";
        }
        return <NavigationItem key={translatedText}
                               path={navigationItem.route}
                               text={translatedText}
                               icon={icon}/>;
    });
};

const Navigation = () => {
    const {t}  = useTranslation();
    const [navigationItems, setNavigationItems] = useState<Array<JSX.Element>>();
  
    useEffect(() => {
        navigationStore.registerListener(() => {
            setNavigationItems(getNavigationStoreItems());
        });
        return () => {
            navigationStore.unregisterListeners();
        };
    }, []);

    useEffect(() => {
        setNavigationItems(getNavigationStoreItems());
    }, [t]);

    const history = useHistory();

    const goBack = () => {
        const route = navigationStore.goBackRoute();
        history.push(route);
    };
    if (navigationItems && navigationItems.length) {
        return <NavigationWrapper>
            <ArrowBackWrapper onClick={goBack}>
                <NavigationItem path={'#'} icon={<ArrowBackIcon/>}/>
            </ArrowBackWrapper>
            <NavigationItems>
                {navigationItems}
            </NavigationItems>
        </NavigationWrapper>;
    } else {
        return <EmptyNavigation/>;
    }
};

export const NavigationHistory = memo(Navigation);
