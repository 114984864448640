import React, {memo} from "react";
import {borderStyles, CalendarLegendItem} from "../calendar-legend-item/calendar-legend-item";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const LegendStyle = styled.div`
	display: flex;
	flex: 0 0 100%;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
`;

const LegendItemsStyle = styled.div`
	display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const Legend = () => {
    const {t} = useTranslation();
    return <LegendStyle>
        <LegendItemsStyle>
            <CalendarLegendItem text={"9"}
                                explanation={t("instabook.legend.legend_available")}
                                unaivalable={false}/>
            <CalendarLegendItem icon={"waiting"} text={"10"}
                                explanation={t("instabook.legend.legend_waiting")}
                                unaivalable={false}/>
            <CalendarLegendItem borderStyle={borderStyles.dashed} text={"11"}
                                explanation={t("instabook.legend.legend_full")}
                                unaivalable={true}/>
            <CalendarLegendItem borderStyle={borderStyles.solid} text={"12"}
                                explanation={t("instabook.legend.legend_closed")}
                                unaivalable={true}/>
        </LegendItemsStyle>
    </LegendStyle>;
};
export const CalendarLegend = memo(Legend);
