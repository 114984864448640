import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import {moduleSettings} from "../../stores/module-settings.store";
import {InformationText} from "../information-text/information-text";

const Message = () => {
    const {i18n} = useTranslation();
    const generalInfoMessage = moduleSettings.getGeneralInfoMessage(i18n.language);
    return <div>{generalInfoMessage ? <InformationText text={generalInfoMessage} /> : ""}</div>;
};
export const GeneralMessage = memo(Message);
