import {getHttpService} from "../services/http.service";
import {PeriodsEndpoint} from "../endpoints/PeriodsEndpoint";
import {Observable} from "rxjs";
import {periodsArray, PeriodsResponse} from "../types";
import {selectionStore} from "../stores/selection.store";
import i18n from "../i18n";

const httpService = getHttpService();

export const getAvailablePeriods = (): Observable<PeriodsResponse> => {
    const guests = selectionStore.guests.toString();
    const date = selectionStore.date.format("yyyy-M-D").toString();
    const endpoint = new PeriodsEndpoint();
    const queryParams = [{
        queryParamName: "date",
        queryParamValue: date
    }, {
        queryParamName: "persons",
        queryParamValue: guests
    }];
    if (selectionStore.period && periodsArray.includes(selectionStore.period)) {
        queryParams.push({
            queryParamName: "period",
            queryParamValue: selectionStore.period
        });
    }
    if (selectionStore.area && !isNaN(+selectionStore.area)) {
        queryParams.push({
            queryParamName: "area_id",
            queryParamValue: selectionStore.area
        });
    }

    queryParams.push({
        queryParamName: "show_empty_periods",
        queryParamValue: "true"
    });

    queryParams.push({
        queryParamName: "locale",
        queryParamValue: i18n.language
    });


    queryParams.push({
        queryParamName: "expands[]",
        queryParamValue: "prepayment_hours_info"
    });
    endpoint.setQueryParams(queryParams);
    return httpService.get(endpoint);
};
