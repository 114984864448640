// import {getTagManagerArgs} from "../requests/tag-manager.request";
// import TagManager, {TagManagerArgs} from "react-gtm-module";
import {TagManagerOptions} from "../types";


class TagManagerService {
    private restaurantId: string;
    private init: boolean;

    constructor(restaurantId: string) {
        this.restaurantId = restaurantId;
        this.init = false;
    }

    public pushTags(step: string, options?: TagManagerOptions): void {
        // getTagManagerArgs(this.restaurantId, step, options).subscribe((resp: TagManagerArgs) => {
        //     const tagManagerArgs = resp;
        //     if (tagManagerArgs !== null) {
        //         if (!this.init) {
        //             TagManager.initialize({"gtmId": tagManagerArgs.gtmId});
        //             this.init = true;
        //         }
        //         TagManager.dataLayer(tagManagerArgs);
        //     }
        // }, (error: any) => {
        //     console.log("err: ", error);
        // });
    }
}

let tagManagerService: TagManagerService;

export const getTagManagerService = (restaurantId?: string): TagManagerService => {
    if (!tagManagerService && restaurantId !== undefined) {
        tagManagerService = new TagManagerService(restaurantId);
    }
    return tagManagerService;
};
