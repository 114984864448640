import React, {memo, useEffect, useState} from "react";
import {TimeSection} from "../time-section/time-section";
import {getAvailablePeriods} from "../../../requests/periods.requests";
import {AvailableCommercials, BookingState, Hour, PendingBooking, Period, PeriodsResponse} from "../../../types";
import {Loader} from "../../loader/loader";
import {CalendarFiltersPosition} from "../../calendar/calendar-filters/calendar-filters";
import {ITimeSlot} from "../time-slot/time-slot";
import {Redirect, useLocation} from "react-router-dom";
import {getFiltersService} from "../../../services/filters.service";
import {TimeFilters} from "./time-filters";
import {getTagManagerService} from "../../../services/tag-manager.service";
import {MainNavigationRoutes, navigationStore} from "../../../stores/navigation.store";
import {buildRouteWithParams} from "../../../helpers/build-route-with-params";
import {config} from "../../../config";
import {TimeSlotLegend} from "../time-slot-legend/time-slot-legend";
import {useErrorHandler} from 'react-error-boundary'
import { RuntimeErrorImpl } from "../../../services/api-errors/api-errors-impl";
import { getCommercialsService } from "../../../services/commercials.service";
import { getCreatePendingBookingService } from "../../../services/create-pending-booking.service";
import {moduleSettings} from "../../../stores/module-settings.store";
import {
    cancelPendingBookingRequest,
    createInitialPendingBookingRequest
} from "../../../requests/pending-booking.requests";
import {commercialsStore} from "../../../stores/commercials.store";
import {pendingBookingStore} from "../../../stores/pending-booking.store";
import { selectionStore } from "../../../stores/selection.store";
import { getRequestParamsService } from "../../../services/request-params.service";
import { NoTimeSection } from "../no-time-section/no-time-section";
import { useTranslation } from "react-i18next";

interface IDisplayPeriod {
    titleKey: string;
    hours?: Array<ITimeSlot>;
    noHourReason?:string;
}

const Time = () => {
    const [displayPeriods, setDisplayPeriods] = useState<Array<IDisplayPeriod>>([]);
    const [selectedHour, setSelectedHour] = useState(null);
    const [dropdownChanged, setDropdownChanged] = useState<any>("");
    const [filters, setFilters] = useState<any>({});
    const [requestingData, setRequestingData] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const [hasPeriods, setHasPeriods]=useState(true);
    const [engineMode, ]=  useState(useLocation().state);
    
    const {i18n} = useTranslation();
    const dropdownFilterChanged = (value: any) => {
        setDropdownChanged(value);
        setRequestingData(true);
    };

    const onSetIsLoading = (isLoading: boolean, redirect: boolean) => {
        setRequestingData(isLoading);
        setRedirect(redirect);
    };

    const throwError = useErrorHandler();

    useEffect(() => {
        navigationStore.currentRoute = buildRouteWithParams(config.routes.selectionTime);
    }, []);

    useEffect(() => {
        if (!hasPeriods){
            throwError(new RuntimeErrorImpl());
        }
    }, [hasPeriods, throwError]);

    useEffect(() => {
        if(selectedHour){
            onSetIsLoading(true, false);
            selectionStore.setSelectedCommercial(AvailableCommercials.menus, false);
            selectionStore.setSelectedCommercial(AvailableCommercials.offers, false);
            if (selectionStore.time === selectedHour) {
                onSetIsLoading(false, true);
                return;
            }
            selectionStore.time = selectedHour;
            navigationStore.addTimeToHistory();
            const initialPendingBooking = getCreatePendingBookingService().createInitialPendingBooking();
            if (moduleSettings.initialBookingCreated) {
                moduleSettings.initialBookingCreated = false;
                cancelPendingBookingRequest();
            }
            const {bookingId, bookingToken} = getRequestParamsService().getParams();
            const subscription = createInitialPendingBookingRequest(initialPendingBooking, bookingId, bookingToken).subscribe((resp: PendingBooking) => {
                moduleSettings.initialBookingCreated = true;
                pendingBookingStore.pendingBooking = resp;
                let commercials = [];
                if (resp.available_commercials) {
                    commercials = [...resp.available_commercials];
                }
                if (resp.available_menus) {
                    commercials = [...commercials, ...resp.available_menus];
                }
                commercialsStore.mapCommercialsToSpecific(commercials);
                onSetIsLoading(false, true);
                // setRedirect(true);
            }, throwError);
        
            return () => {
                subscription.unsubscribe();
            };
        }
      
    }, [selectedHour, throwError])

    useEffect(() => {
        if (!selectionStore.date) {
            return;
        }
        const dispPeriod: Array<IDisplayPeriod> = [];
        const subscription = getAvailablePeriods().subscribe((resp: PeriodsResponse) => {
            const periods = resp.periods;
            const selectedTime = selectionStore.time;
            periods.forEach((period: Period) => {
                if(period.hours.length === 0){
                    dispPeriod.push({titleKey: period.period,noHourReason: period.no_hour_reason})
                }else{
                    const hours: Array<ITimeSlot> = [];
                    period.hours.forEach((hour: Hour) => {
                        hours.push({
                            hour: hour.time,
                            icons: {
                                hasOffer: hour.event || (hour.offer && hour.offer.length > 0),
                                hasWaitingList: hour.booking_state === BookingState.waiting,
                                hasPrepayment: hour.has_prepayment
                            },
                            onTimeSelect:() => {
                                setSelectedHour(hour.time);
                            },
                            isSelected: selectedTime && selectedTime === hour.time
                        });
                    });
                    dispPeriod.push({titleKey: period.period, hours: hours});
                }
            });

            getTagManagerService().pushTags("hours");
            setDisplayPeriods(dispPeriod);
            setFilters(getFiltersService().getFiltersFromPeriods(resp.periods, engineMode && engineMode["engineMode"]));
            setRequestingData(false);
            setHasPeriods(periods.length !==0)
        },  throwError);

        return () => {
            subscription.unsubscribe();
        };
    }, [dropdownChanged, i18n.language, throwError]);
  
    const periodsView = displayPeriods.map((displayPeriod: IDisplayPeriod) => {
        if(displayPeriod.hours){
            return <TimeSection key={displayPeriod.titleKey}
            sectionTitleKey={displayPeriod.titleKey}
            sectionTimeSlots={displayPeriod.hours}
            onSetIsLoading={onSetIsLoading}/>
        }else{
            return <NoTimeSection key={displayPeriod.titleKey}
                sectionTitleKey={displayPeriod.titleKey}
                noHourReason={displayPeriod.noHourReason}
            />
        }
    });

    if (redirect) {
        const commercialsService = getCommercialsService();
        if (!commercialsService.shouldDisplayCommercial()) {
            navigationStore.changeMainNavigationRouteState(MainNavigationRoutes.details, true);
            return <Redirect to={buildRouteWithParams(config.routes.details)}/>;
        }
        const nextStepCommercial = commercialsService.nextStepCommercial();
        return <Redirect
            to={buildRouteWithParams(config.routes.selectionCommercials) + `/${nextStepCommercial}`}/>;
    };

    return  requestingData || redirect?   <Loader/>  :
        
        <div>
                <TimeFilters
                    alignPosition={CalendarFiltersPosition.start}
                    onDropdownItemChanged={dropdownFilterChanged}
                    areas={filters.areas}
                    periods={filters.periods}
                />
                    {periodsView}
                <TimeSlotLegend/>
        </div>
};
export const SelectionTime = memo(Time);
