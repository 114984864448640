import {Booking} from "../types";

class BookingStore {
    private _booking: Booking = {} as Booking;

    set booking(bookingResponse: Booking) {
        this._booking = bookingResponse;
    }

    get booking(): Booking {
        return this._booking;
    }
}

export const bookingStore = new BookingStore();
