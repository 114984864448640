import React, {memo} from "react";
import styled from "styled-components";
import { ThemeManager } from "../../../theme-manager/theme-manager";
import { ITimeSlotIcons, TimeSlotIcons } from "../time-slot-icons/time-slot-icons";

const activeTheme = ThemeManager.getTheme();
export interface ITimeSlot {
    hour: string;
    icons: ITimeSlotIcons;
    onSetIsLoading?: any;
    onTimeSelect: (hour:string) => void;
    isSelected: boolean;
}

const SlotStyle = styled.div`
  display: inline-block;
  text-align: center;
  color: ${activeTheme.textColor};
  position: relative;
  text-decoration: none;
  padding: 6px 0;
  margin-right: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;
  height: 30px;
  width: 60px;

  &:hover, &.isSelected {
    background-color: ${activeTheme.primaryColor};
    color: ${activeTheme.invertFontColor};
  }
`;

const Slot = (props: ITimeSlot) => {
    const onSelect = () =>{
        props.onTimeSelect(props.hour)
    }

    return <SlotStyle onClick={onSelect} className={props.isSelected ? "isSelected" : ""}>
        {props.icons ? <TimeSlotIcons icons={props.icons}/> : ""}
        <span>{props.hour}</span>
    </SlotStyle>;
};
export const TimeSlot = memo(Slot);
