import {Endpoint} from "./BaseEndpoint";

export class BookingEndpoint extends Endpoint {
    constructor(bookingId?: number) {
        super("bookings");
        if (bookingId) {
            this.endPoint += `/${bookingId}`;
        }
    }
}

export class InviteFriendsEndpoint extends BookingEndpoint {
    constructor(bookingId: number) {
        super(bookingId);
        this.endPoint += "/invite_friends";
    }
}
