import React, {memo} from "react";
import {config} from "../../../config";
import {buildRouteWithParams} from "../../../helpers/build-route-with-params";
import {Route, Switch} from "react-router-dom";
import {ConfirmationPayment} from "../confirmation-payment/confirmation-payments";
import {ConfirmationValidation} from "../confirmation-validation/confirmation-validation";
import {ConfirmationContactForm} from "../confirmation-contact-form/confirmation-contact-form";
import { NavigationHistory } from "../../navigation-history/navigation-history";

const Confirmation = () => {
    return <div>
        <Switch>
            <Route exact={true} path={buildRouteWithParams(config.routes.confirmation)}>
                <NavigationHistory/>
                <ConfirmationContactForm/>
            </Route>
            <Route path={buildRouteWithParams(config.routes.confirmationPayment)}>
                <ConfirmationPayment/>
            </Route>
            <Route path={buildRouteWithParams(config.routes.confirmationEnd)}>
                <ConfirmationValidation/>
            </Route>
        </Switch>
    </div>;
};
export const ConfirmationRouter = memo(Confirmation);
