/* eslint-disable no-use-before-define */
import React, { memo } from 'react';
import {useTranslation} from "react-i18next";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import ReactCountryFlag from "react-country-flag"

interface IProps {
  onCountryChange: any;
  countries: Array<Country>;
  defaultValue:Country;
}

interface Country {
  code: string;
  label: string;
  phone: string;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const CountrySelectWrapper = (props: IProps) => {

  const {t} = useTranslation();
  const classes = useStyles();
  const handleSelectCountry = (event: any, value: any) => {
    props.onCountryChange(value);
  }

  return (
    <Autocomplete
      id="country-select"
      disableClearable
      // style={{ width: 200 }}
      options={props.countries}
      value={props.defaultValue}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      getOptionLabel={(option: Country) => "+"+option.phone}
      renderOption={(option: Country) => (
        <div>
          <ReactCountryFlag countryCode={option.code} svg/>
          {" "}{option.label} +{option.phone}
        </div>
      )}
      onChange={handleSelectCountry}
      renderInput={(params) => (
        <TextField
        {...params}
        label={t("instabook.contacts.prefix")}
        variant="outlined"
        required={true}
        inputProps={{
            ...params.inputProps,
            // autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

export const CountrySelect = memo(CountrySelectWrapper);

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
