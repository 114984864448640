import React, {memo} from "react";
import styled from "styled-components";
import {ThemeManager} from "../../../theme-manager/theme-manager";
import {useTranslation} from "react-i18next";
import {Booking} from "../../../types";
import {setDangerouslyInnerHtml} from "../../../helpers/set-inner-html";

const activeTheme = ThemeManager.getTheme();

interface IStatusProps {
    color: StatusColors
    booking: Booking
}

export enum StatusColors {
    green = "green"
}

const StatusWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StatusText = styled.div`
  color: white;
  padding: 20px 60px;
  border-radius: 5px;
  margin-bottom: 36px;
  
  &.green {
    background-color: ${activeTheme.reservationSuccess};
  }
`;

const Status = (props: IStatusProps) => {
  const {t} = useTranslation();
  const status = props.booking.booking_state
  
  let success_msg = {}
  
  switch (status) {
    case "validated":
      success_msg = t("instabook.bookings.success.info")
      break;
    case "initialized":
      success_msg = t("instabook.bookings.success.waiting_for_confirmation")
      break;
    case "waiting":
      success_msg = <span
      dangerouslySetInnerHTML={setDangerouslyInnerHtml(t, "instabook.bookings.success.waiting", {
          rank: props.booking.waiting_rank,
      })}/>
      break;
    default:
      success_msg = t("instabook.bookings.success.info")
  }
  
  return <StatusWrapper>
            <StatusText className={props.color}>
              {success_msg}
            </StatusText>
          </StatusWrapper>
}

export const ReservationStatus = memo(Status);
