export const assignProps = (target: any, source: any): any => {
    Object.keys(source).forEach((key, index) => {
        if (source[key] !== undefined) {
            target[key] = source[key];
        }
    });
    return target;
};

export const numberFormat = (locale: string, value:number, currencyFormat:string) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyFormat,
  }).format(value);


export const capitalize = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}