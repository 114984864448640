import { AxiosError } from "axios";
import { ApiError } from './error-types';
import { AccessDeniedApiErrorImpl, ApiErrorImpl, BadRequestApiErrorImpl, ResourceNotFoundApiErrorImpl, ValidationErrorApiErrorImpl } from './api-errors-impl';

class ApiErrorFactory {

    // Returns ApiError based given Axios error
    public getApiError(err: AxiosError): ApiError {
        if (err.response) {
            let status = err.response.status
            switch(status){
                case 401: case 403:{
                    return new AccessDeniedApiErrorImpl(err);
                }
                case 400:{
                    return new BadRequestApiErrorImpl(err);
                }
                case 404:{
                    return new ResourceNotFoundApiErrorImpl(err);
               }
               case 422:{
                   return new ValidationErrorApiErrorImpl(err);
               }
            }
        }
        return new ApiErrorImpl(err);
    }
}

let instance: ApiErrorFactory;

export const getApiErrorFactory = (): ApiErrorFactory => {
    if (!instance) {
        instance = new ApiErrorFactory();
    }
    return instance;
};