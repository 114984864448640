import React, {memo, useEffect, useState} from "react";
import styled from "styled-components";
import {MenuItem, OutlinedInput, Select} from "@material-ui/core";
import {DropdownOption, NormalizedOption} from "../../types";

interface IProps {
    options: Array<DropdownOption>,
    placeholderOption: DropdownOption,
    isGuestsSelection?: boolean,
    placeholderIcon?: string,
    placeholderSelectable?: boolean,
    onItemClick?: (event: React.ChangeEvent<{ value: any }>) => void,
    icon?: any;
}

const DropdownStyle = styled.div`
  position: relative;
`;

const CustomIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 0;
`;

const CustomSelect = styled(Select)`
  min-width: 200px;
  z-index: 10;

  &.custom-icon {
    /* padding-right: 40px; */

    svg {
      display: none;
    }
  }
  
  &.guests-selection {
    min-width: 100px;
  }
`;

const normalizeOption = (option: string) => {
    return option.split(" ").join("-").toLowerCase();
};

const DropdownComponent = (props: IProps) => {
    const optionClicked = (itemValue: any) => {
        const value = itemValue;
        options.forEach((option) => {
            option.isSelected = option.value === value;
        });
        setOptions([...options]);
        if (props.onItemClick) {
            props.onItemClick(itemValue);
        }
    };
    const [options, setOptions] = useState<Array<NormalizedOption>>([...props.options]);
    const placeholderOption = props.placeholderOption;
    const defaultOptionSelectable = props.placeholderSelectable;
    useEffect(() => {
        const normalizedOptions = props.options.map((option: DropdownOption) => {
            return {
                ...option,
                isSelected: option.isSelected,
                normalized: normalizeOption(option.value)
            };
        });
        setOptions([...normalizedOptions]);
    }, [props]);
    const getValue = () => {
        const item = options.find((option) => option.isSelected);
        return item ? item.value : props.placeholderOption.value;
    };
    const guestsClass = props.isGuestsSelection ? "guests-selection" : "";
    const iconClass = props.icon ? "custom-icon" : "";
    return <DropdownStyle>
        <CustomSelect
            className={`${guestsClass} ${iconClass}`}
            value={getValue()}
            input={
                <OutlinedInput
                    name={"outline"}
                    labelWidth={0}
                />
            }>
            <MenuItem disabled={!defaultOptionSelectable}
                      key={normalizeOption(placeholderOption.value)}
                      value={placeholderOption.value}
                      onClick={() => optionClicked(placeholderOption.value)}>
                {placeholderOption.displayText}
            </MenuItem>
            {options?.map((option: NormalizedOption, index: number) => {
                return <MenuItem key={index}
                                 value={option.value}
                                 selected={option.isSelected}
                                 onClick={() => optionClicked(option.value)}>
                    {option.displayText}
                </MenuItem>;
            })}
        </CustomSelect>
        {props.icon ? <CustomIcon>{props.icon}</CustomIcon> : ""}
    </DropdownStyle>;
};
export const Dropdown = memo(DropdownComponent);
