import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {ThemeManager} from "../../theme-manager/theme-manager";
import { Loader } from "../loader/loader";
import { ButtonStyle } from "../../types";

const activeTheme = ThemeManager.getTheme();

interface IProps {
    buttonTextKey: string;
    route?: string;
    onClick?: any;
    position?: AcceptedPositions;
    id?: string;
    disabled?:boolean;
}

interface IStyle{
  disabled:boolean;
}

export enum AcceptedPositions {
    right = "right",
    center = "center",
    left = "left"
}

const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 30px 0 20px;
  cursor: pointer;

  &.right {
    text-align: right;
  }

  &.center {
    text-align: center;
  }

  &.left {
    text-align: left;
  }
`;

const ButtonLinkStyle = styled(Link)<IStyle>`
  display: inline-block;
  color: ${activeTheme.buttonStyle === ButtonStyle.contained ? activeTheme.invertFontColor : activeTheme.primaryColor};
  text-decoration: ${activeTheme.buttonStyle === ButtonStyle.text ? "underline" : "none"};
  font-size: ${activeTheme.buttonStyle === ButtonStyle.text ? "16px" : "14px"};
  padding: 12px ${activeTheme.buttonStyle === ButtonStyle.text ? "12px" : "24px"};
  background-color: ${activeTheme.buttonStyle === ButtonStyle.contained ? activeTheme.primaryColor : "transparent"};
  border: ${activeTheme.buttonStyle === ButtonStyle.outlined ? `1px solid ${activeTheme.primaryColor}` : "none"};
  border-radius: 3px;
  opacity: ${props => props.disabled ? 0 : 1 };
  pointer-events: ${props => props.disabled ? "none" : "auto" };
`;

const ButtonDefaultStyle = styled.div<IStyle>`
  display: inline-block;
  color: ${activeTheme.buttonStyle === ButtonStyle.contained ? activeTheme.invertFontColor : activeTheme.primaryColor};
  text-decoration: ${activeTheme.buttonStyle === ButtonStyle.text ? "underline" : "none"};
  font-size: ${activeTheme.buttonStyle === ButtonStyle.text ? "16px" : "14px"};
  padding: 12px ${activeTheme.buttonStyle === ButtonStyle.text ? "12px" : "24px"};
  background-color: ${activeTheme.buttonStyle === ButtonStyle.contained ? activeTheme.primaryColor : "transparent"};
  border: ${activeTheme.buttonStyle === ButtonStyle.outlined ? `1px solid ${activeTheme.primaryColor}` : "none"};
  border-radius: 3px;
  opacity: ${props => props.disabled ? 0 : 1 };
  pointer-events: ${props => props.disabled ? "none" : "auto" };
`;

const Button = (props: IProps) => {
    const {t} = useTranslation();
    return <ButtonWrapper className={props.position || AcceptedPositions.right}>
        {props.disabled && <Loader />}
        {props.route ?
            <ButtonLinkStyle onClick={props.onClick} to={props.route} disabled={props.disabled}>
                {t(props.buttonTextKey)}
            </ButtonLinkStyle>
            :
            <ButtonDefaultStyle onClick={props.onClick} disabled={props.disabled}>
                {t(props.buttonTextKey)}
            </ButtonDefaultStyle>}
    </ButtonWrapper>;
};
export const ContinueButton = memo(Button);
