import { StripeError } from "@stripe/stripe-js";

export enum Periods {
    lunch = "lunch",
    dinner = "dinner",
    afternoon = "afternoon",
    breakfast = "breakfast",
    brunch = "brunch"
}

export const periodsArray: Array<string> = Object.values(Periods);

export enum BookingState {
    initialized = "initialized",
    waiting = "waiting",
    validated = "validated",
    canceled = "canceled"
}

export enum AvailableCommercials {
    menus = "menus",
    offers = "offers"
}

export enum SelectionSteps {
    guests = "guests",
    date = "date",
    area = "area",
    period = "period",
    time = "time",
    offer = "offer",
    menu = "menu"
}

export enum DayStatus{
    waiting = "waiting",
    closed = "closed",
    available = "available",
    full = "full",
    unavailable = "unavailable"
}

export enum EngineMode{
    capacity = "capacitive",
    table = "table"
}

export enum ButtonStyle {
    text = "text",
    contained = "contained",
    outlined = "outlined"
};

export enum HeaderStyle {
    text = "text",
    contained = "contained",
};

export interface DailyLightInfo{
    date: string;
    status: DayStatus; 
    engine_mode:EngineMode;
}

export interface DailyLightInfoResponse{
    daily_availabilities: Array<DailyLightInfo>;
}

export interface GuestsCapacity {
    minGuests: number;
    maxGuests: number;
}

export interface SelectionCommercial {
    type: AvailableCommercials;
    commercials: Array<Commercial>;
    commercialsKey: CommercialKeys;
}

export enum CommercialKeys {
    menus = "menus",
    offers = "offers",
    events = "events"
}

export interface BaseInterface {
    object: string;
}

export interface Area extends BaseInterface {
    id: number;
    bookable_online: boolean;
    translations: Array<AreaTranslation>;
}

export interface AreaTranslation extends BaseInterface {
    locale: string;
    name: string;
}

export interface RestaurantBookingDetail extends BaseInterface {
    note: { [key: string]: string };
}

export interface Period extends BaseInterface {
    area_id?: number;
    booking_details: Array<BookingDetail>;
    date: string
    hours: Array<Hour>;
    period: Periods;
    persons: number;
    restaurant_id: number;
    restaurant_name: string;
    total_left_seats: number;
    no_hour_reason:string;
}

export interface PeriodsResponse {
    periods: Array<Period>;
    next_available_date?:string;
    message?:string;
}

export interface PeriodsRequest {
    date: string;
    persons: number;
    restaurant_id?: number;
    area_id?: number;
    period?: Periods;
    expand?: any;
}

export interface Hour extends BaseInterface {
    bookable_area_ids: Array<number>;
    booking_state: "validated" | "initialized" | "waiting";
    event: any;
    waitingList?: boolean;
    offer: Array<any>;
    time: string;
    has_prepayment: boolean;
}

export interface BookingDetail extends BaseInterface {
    note: Array<BookingDetailTranslation>
}

export interface BookingDetailTranslation {
    [key: string]: string;
}

export interface InitialPendingBookingRequest {
    area_id?: string;
    date: string;
    hour: string;
    persons: number;
    restaurant_id: number;
    booking_token?: string;
    contact_info?: PendingBookingContact;
    metadata?: any;
    user_locale?: string;
}

export interface PendingBooking extends BaseInterface {
    area_id?: null;
    available_commercials: Array<Commercial>;
    available_menus: Array<Menu>;
    booking_state: BookingState;
    bookings_detail?: RestaurantBookingDetail;
    commitable: boolean;
    contact_info: PendingBookingContact;
    date: string;
    hour: string;
    id: string;
    menu_selection_requested?: false;
    metadata: any;
    offer: CommercialLight;
    opening_id?: string;
    optional: boolean;
    persons: number;
    prepayment: Prepayment;
    restaurant_id: number;
    selected_menus: any;
    user_locale?: string;
    voucher_enabled: boolean;
    voucher_infos?: any;
    vouchers: Array<any>;
}

export interface PendingBookingContact {
    lastname: string;
    firstname: string;
    mobile_phone: string;
    country_code: string;
    email: string;
    message?: string;
    gender?: number;
    birthday?: string;
    company?: string;
    allergies?: string;
}

export interface Booking extends BaseInterface {
    id: number;
    booking_state: BookingState;
    booked_persons_number: number;
    present_persons_number: number;
    cancel_actor: null;
    cancel_reason: null;
    booking_type: string;
    note: null;
    period: Periods;
    booking_date: string;
    booking_time: string;
    email: string;
    firstname: string;
    lastname: string;
    send_reminders: boolean;
    user_locale: string;
    metadata: any;
    contact_id: number;
    restaurant_id: number;
    waiting_rank: number;
}

export interface Prepayment {
    client_secret: string;
    intent_id: string;
    stripe_account: any
    kind: string;
    amount: number;
    stripe_key: string;
    payment_kind: string;
    currency: string;
}

export interface CommercialLight extends BaseInterface {
    commercial_id: number;
}

export interface SelectedMenu {
    id: string;
    quantity: number;
}

export interface QueryParam {
    queryParamName: string;
    queryParamValue: string;
}

export enum CommercialsObject {
    menu = "menu",
    event = "event",
    offer = "offer"
}

export interface CommercialsResponse {
    commercials: Array<Commercial>;
    current_page: number;
    num_pages: number;
    total_count: number;
    total_pages: number;
}

export interface PaymentSecretResponse {
    secret: string;
}

export interface Commercial extends BaseInterface {
    bookable: boolean;
    commercial_recurrencies: Array<CommercialRecurrence>;
    description: string;
    end_date: string | null;
    end_time: string | null;
    has_prepayment: boolean;
    id: number;
    maximum_dishes: number;
    minimum_dishes: number;
    name: string;
    permanent: boolean;
    restaurant_id: number;
    start_date: any | null;
    start_time: string | null;
    translation: Array<CommercialTranslation>;
}

export interface Menu extends Commercial {
    left: number;
}

export interface CommercialTranslation extends BaseInterface {
    locale: string;
    name: string;
    description: string;
}

export interface CommercialRecurrence extends BaseInterface {
    end_hour: string;
    month_week: number;
    start_hour: string;
    week_day: number;
}

export interface CustomContactFormFields {
    accept_partner_spam: boolean;
    allow_blank_email: boolean;
    show_birthday: boolean;
    show_company_on_module: boolean;
    show_tol_span: boolean;
    show_allergies_terms: boolean;
    partner_name: string;
}

export interface UrlParams {
    referralKey: string;
    version?: string;
    restaurantId: string;
    bookingId?: string;
}

export interface IThemeProps {
    primaryColor?: string;
    secondaryColor?: string;
    backgroundColor?: string;
    headerBackgroundColor?: string;
    headerStyle?: HeaderStyle;
    textColor?: string;
    textColorGray?: string;
    disabledColor?: string;
    reservationSuccess?: string;
    buttonStyle?: ButtonStyle;
}

export interface ModuleSettings extends BaseInterface, CustomContactFormFields {
    available_locales: Array<string>;
    callback_url?: string | null;
    restaurant: SettingsRestaurant;
    show_powered_by: boolean;
    side_picture_url: string;
    logo_restaurant_url: string;
    referral_key: string;
    booked_persons_number: number;
    booking_date: moment.Moment;
    terms?: Terms;
    partner_display_name: string;
    show_sharing_friend_on_module: boolean;
    theme: IThemeProps;
    show_allergies_terms_on_module: boolean;
}

export interface SettingsRestaurant extends BaseInterface {
    areas: Array<Area>;
    bookings_detail: RestaurantBookingDetail;
    capacity: number;
    events: Array<any>;
    first_step: string;
    id: number;
    imageUrl?: string;
    last_booking_date: string;
    locale: string;
    menus: Array<any>
    name: string;
    offers: Array<any>;
    periods: Array<Periods>;
    online_booking_enabled: boolean;
}

export interface Terms {
    disclaimer_url: string;
    prepayment_disclaimer_url: string;
    privacy_policy_url: string;
}

export interface DropdownOption {
    value: any;
    displayText: string;
    isSelected?: boolean;
}

export interface NormalizedOption extends DropdownOption {
    normalized?: string;
}

export interface TagManagerOptions {
    first_step?: boolean;
    booking_id?: string;
    pending_booking_id?: string;
    guests?: number;
}


export interface GolReducer {
    observeProps:Array<string>;
    reducer : () => void;
}

export interface GolError{
    errorMessage:Array<string>;
    field:string;
}

export interface GolResourceError{
    resource:string;
    errors:Array<GolError>;
}

export interface GolStripeCallbackResponse{
    stripeToken?:string;
    stripeError?:StripeError;
}

