import moment from "moment";
import {AvailableCommercials, Commercial, SelectionSteps} from "../types";
import {moduleSettings} from "./module-settings.store";
import {getCommercialsService} from "../services/commercials.service";
import {MainNavigationRoutes, navigationStore} from "./navigation.store";

require("moment/min/locales.min");

class SelectionStore {
    private _date: moment.Moment | undefined;
    private _calendarDate: moment.Moment = moment().startOf("month");
    private _time: string = "";
    private _commercials: Array<Commercial> = [];
    private _guests: number = 0;
    private _period: string | null = null;
    private _area: string | null = null;
    private selectedCommercials = {
        menus: false,
        offers: false
    };
    private _menuCount: number = 0;
    private steps: Array<SelectionSteps> = [];
    private _firstStepCommercial: { completed: boolean; selected: boolean } = {
        completed: false,
        selected: false
    };

    public resetStore(){
        this.guests = 0;
        this.date = moment().subtract(1,"days");
        this.time = "";
        this.area = null;
        this.period = null;
        this.calendarDate = moment().startOf("month");
        this.menuCount = 0;
        this.clearCommercialStep(AvailableCommercials.offers);
        this.clearCommercialStep(AvailableCommercials.menus);
    }

    public get guests(): number {
        return this._guests;
    }

    public set guests(guestNumber: number) {
        this._guests = guestNumber;
    }

    public get date(): moment.Moment {
        return this._date!;
    }

    public set date(newDate: moment.Moment) {
        this._date = newDate;
    }

    public get time() {
        return this._time;
    }

    public set time(newTime: string) {
        this._time = newTime;
    }

    public get commercials(): Array<Commercial> {
        return this._commercials;
    }
  

    
    public addCommercial(commercial: Commercial) {
        const index = this._commercials.findIndex((oldCommercial: Commercial) => oldCommercial.object === commercial.object);
        if (index !== -1) {
            this._commercials[index] = commercial;
        } else {
            this._commercials.push(commercial);
        }
    }

    public set menuCount(newMenuCount: number) {
        this._menuCount = newMenuCount;
    };

    public get menuCount(): number {
        return this._menuCount;
    }

    public setSelectedCommercial(key: AvailableCommercials, isSelected: boolean) {
        this.selectedCommercials[key] = isSelected;
    }

    public getSelectedCommercial(key: AvailableCommercials) {
        return this.selectedCommercials[key];
    }

    public set period(selectedPeriod: string | null) {
        this._period = selectedPeriod;
    }

    public get period(): string | null {
        return this._period;
    }

    public set area(selectedArea: string | null) {
        this._area = selectedArea;
    }

    public get area(): string | null {
        return this._area;
    }

    public setFirstStepCommercial(isCompleted: boolean, isSelected: boolean) {
        this._firstStepCommercial = {
            completed: isCompleted,
            selected: isSelected
        };
    }

    public get firstStepCommercial(): { completed: boolean, selected: boolean } {
        return this._firstStepCommercial;
    }

    public get calendarDate(): moment.Moment {
        return this._calendarDate;
    }

    public set calendarDate(newDate: moment.Moment) {
        this._calendarDate = newDate;
    }

    public clearSelection(currentStep: SelectionSteps) {
        const stepIndex = this.steps.findIndex((step: SelectionSteps) => step === currentStep);
        if (stepIndex !== -1) {
            for (let i = stepIndex + 1; i < this.steps.length; i++) {
                this.clearStep(this.steps[i]);
            }
        }
    }

    public clearStep(currentStep: SelectionSteps) {
        switch (currentStep) {
            case SelectionSteps.guests:
                this.guests = 0;
                break;
            case SelectionSteps.date:
                this.date = moment().subtract(1,"days");
                break;
            case SelectionSteps.time:
                this.time = "";
                break;
            case SelectionSteps.offer:
                this.clearCommercialStep(AvailableCommercials.offers);
                break;
            case SelectionSteps.menu:
                this.clearCommercialStep(AvailableCommercials.menus);
                break;
            default:
                break;
        }
        navigationStore.changeMainNavigationRouteState(MainNavigationRoutes.details, false);
        navigationStore.changeMainNavigationRouteState(MainNavigationRoutes.confirmation, false);
    }

    public clearCommercialStep(commercialType: AvailableCommercials) {
        const commercialService = getCommercialsService();
        if (this.getSelectedCommercial(commercialType)) {
            const commercialIndex = this.commercials.findIndex((commercial: Commercial) =>
                commercialService.mapCommercialTypeToRoute(commercial.object) === commercialType);
            if (commercialIndex !== -1) {
                const removedItems = this._commercials.splice(commercialIndex, this._commercials.length - commercialIndex);
                removedItems.forEach((removedItem) => {
                    const removedItemType = commercialService.mapCommercialTypeToRoute(removedItem.object);
                    this.setSelectedCommercial(removedItemType, false);
                });
            }
        }
        if (commercialType === AvailableCommercials.menus) {
            selectionStore.menuCount = 0;
        }
        this.setSelectedCommercial(commercialType, false);
    }

    public createSteps() {
        this.steps = [
            SelectionSteps.guests,
            SelectionSteps.date,
            SelectionSteps.area,
            SelectionSteps.period,
            SelectionSteps.time,
            SelectionSteps.offer,
            SelectionSteps.menu,
        ];
        if (moduleSettings.hasCommercialFirstStep()) {
            const firstStepCommercial = moduleSettings.getFirstStepCommercials();
            switch (firstStepCommercial.type) {
                case AvailableCommercials.offers:
                    this.steps.splice(5, 1);
                    this.steps = [SelectionSteps.offer, ...this.steps];
                    break;
                case AvailableCommercials.menus:
                    this.steps.splice(6, 1);
                    this.steps = [SelectionSteps.menu, ...this.steps];
                    break;
                default:
                    break;
            }
        }
    }
}

export const selectionStore = new SelectionStore();
