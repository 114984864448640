import React, {memo} from "react";
import styled from "styled-components";

interface ICommercialTitleProps {
    title: string;
    icon?: any;
}

const TitleDisplay = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &.has-icon {
    padding-left: 20px;

    &:before {
      content: ' ';
    }
  }
`;

const Title = (props: ICommercialTitleProps) => {
    return <TitleDisplay>
        {props.icon ? props.icon : ""}
        {props.title}
    </TitleDisplay>;
};

export const CommercialTitle = memo(Title);
