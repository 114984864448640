import React, {memo, useEffect, useState} from "react";
import {getCommercialsService} from "../../../services/commercials.service";
import {DetailsLayout} from "../../details/details-layout/details-layout";
import {SelectionCommercials} from "../selection-commercials/selection-commercials";
import {useParams} from "react-router-dom";
import {AvailableCommercials, SelectionCommercial} from "../../../types";
import {moduleSettings} from "../../../stores/module-settings.store";
import { Loader } from "../../loader/loader";

const Router = () => {
    const commercialsService = getCommercialsService();
    const {commercialType} = useParams<{ commercialType: AvailableCommercials }>();
    const [commercials, setCommercials] = useState<SelectionCommercial | null>();
    const [isFirstStep, setIsFirstStep] = useState(false);
    const [isLoading, setIsLoading] =useState(true)
    useEffect(() => {
        if (commercialType !== null) {
            if (moduleSettings.hasCommercialTypeFirstStep(commercialType)) {
                setCommercials(moduleSettings.getFirstStepCommercials());
                setIsFirstStep(true);
            } else {
                setCommercials(commercialsService.getCommercialsByType(commercialType as AvailableCommercials));
            }
        } else {
            setCommercials(commercialsService.getCommercials());
        }
        setIsLoading(false)
    }, [commercialType, commercialsService]);

    const content = commercials ? <SelectionCommercials isFirstStep={isFirstStep} commercials={commercials}/> : <DetailsLayout/>

    return <div>
        {isLoading ? <Loader/> : content}
    </div>;
};

export const CommercialsRouter = memo(Router);
