class LanguageSwitcherService {
    public listeners: Array<() => void> = [];

    public registerListener = (listener: () => void) => {
        this.listeners.push(listener);
    };

    public unregisterListeners = () => {
        this.listeners = [];
    };

    public callListeners = () => {
        this.listeners.forEach((listener) => {
            listener();
        });
    }
}

let languageSwitcherService: LanguageSwitcherService;

export const getLanguageSwitcherService = (): LanguageSwitcherService => {
    if (!languageSwitcherService) {
        languageSwitcherService = new LanguageSwitcherService();
    }
    return languageSwitcherService;
};
