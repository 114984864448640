import { config } from "../config";
import { UrlParams } from "../types"
import { moduleSettings } from "../stores/module-settings.store";

class RequestParamsService {
    private _referralKey: string;
    private _restaurantId: string;
    private _version?: string;
    private _token?: string;
    private _bookingId?: string;
    private _bookingToken?: string;

    constructor(referralKey: string, restaurantId: string, version?: string, bookingId?: string, bookingToken?: string) {
        this._referralKey = referralKey;
        this._restaurantId = restaurantId;
        this._version = version;
        this._bookingId = bookingId;
        this._bookingToken = bookingToken;
    }

    public getParams(): { referralKey: string, restaurantId: string, version?: string, bookingId?: string, bookingToken?: string } {
        return {
            referralKey: this._referralKey,
            restaurantId: this._restaurantId,
            version: this._version,
            bookingId: this._bookingId,
            bookingToken: this._bookingToken
        };
    }

    public getModuleToken(): string {
        return this._token || config.token;
    }

    public set restaurantId(restaurantId: string) {
        this._restaurantId = restaurantId;
    }
    public set referralKey(referralKey: string) {
        this._referralKey = referralKey;
    }
}

let requestParamsService: RequestParamsService;

export const initRequestParamsService = (urlParams: UrlParams, bookingToken?: string) => {
    let referralKey = urlParams.referralKey;
    let restaurantId = urlParams.restaurantId;
    if (moduleSettings && referralKey == null) {
        referralKey = moduleSettings.getReferralKey();
        restaurantId = moduleSettings.getRestaurantId();
    }
    requestParamsService = new RequestParamsService(referralKey, restaurantId, urlParams.version, urlParams.bookingId, bookingToken);
};

export const getRequestParamsService = (referralKey?: string, restaurantId?: string, version?: string): RequestParamsService => {
    if (!requestParamsService && referralKey && restaurantId) {
        requestParamsService = new RequestParamsService(referralKey, restaurantId, version);
    }
    return requestParamsService;
};
