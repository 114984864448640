import React, { memo } from "react";
import styled from "styled-components";
import { ApiError } from "../../services/api-errors";

interface IApiErrorViewProps {
    error: ApiError
}


const ErrorStyle = styled.div`
	position: relative;
	display: flex;    
	align-items: center;
    justify-content: center;
	border-radius: 5px;	
    height: calc(100vh - 80px);    
`;
const ErrorDescriptionStyle = styled.div`
    background-color: #e74c3c;
	color: #FFFFFF;
    display: flex;
    align-items: center;
    flex-direction: column;	
    width: 50%;
    padding: 15px 22px;
	margin: 20px 0;

    @media screen and (max-width: 780px) {
        width: 90%;
        font-size: 16px;
        line-height: 19px;
      }
`;

const Error = (props: IApiErrorViewProps) => {

    return <ErrorStyle>
        <ErrorDescriptionStyle data-test="error-message">{props.error.getMessage()}</ErrorDescriptionStyle>
    </ErrorStyle >;
}

export const ApiErrorView = memo(Error);
