import axios, {AxiosRequestConfig} from "axios";
import {from, Observable} from "rxjs";

let AxiosService: AxiosClass;

class AxiosClass {
    public request(opts: AxiosRequestConfig): Observable<any> {
        return from(axios.request(opts));
    }
}

export const getAxios = (): AxiosClass => {
    if (!AxiosService) {
        AxiosService = new AxiosClass();
    }
    return AxiosService;
};
