import React, {memo, ReactNode, useEffect, useState} from "react";
import "react-dates/initialize";
import {selectionStore} from "../../stores/selection.store";
import {navigationStore} from "../../stores/navigation.store";
import {config} from "../../config";
import {Redirect, useLocation} from "react-router-dom";
import { Moment } from "moment";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {DayPickerSingleDateController} from "react-dates";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {ThemeManager} from "../../theme-manager/theme-manager";
import {buildRouteWithParams} from "../../helpers/build-route-with-params";
import {moduleSettings} from "../../stores/module-settings.store";
import {getSelectionStoreChangesService} from "../../services/selection-store-changes.service";
import { getDailyAvailabilities } from "../../requests/daily_availabilities.requests";
import { DailyLightInfoResponse, DayStatus, EngineMode } from "../../types";
import { Loader } from "../loader/loader";
import { RoundedIcon } from "../rounded-icon/rounded-icon";
import { capitalize } from "../../utils";

import { useErrorHandler} from 'react-error-boundary'
import { ErrorMessage } from "../errors/message-error-view";
import moment from "moment";

const activeTheme = ThemeManager.getTheme();

const NUMBER_OF_MONTHS = 1;
const DATE_NOW = new Date();
DATE_NOW.setHours(0, 0, 0, 0);

interface IStyle  {
  today: boolean;
}

const LoaderWrapper = styled.div`
  position: relative;
`;

const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 294px;
`;

const CustomDay = styled.div<IStyle>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${activeTheme.primaryColor}20;
  text-decoration: ${props => props.today ? "underline" : "none"};
  text-decoration-color: ${activeTheme.primaryColor};
  border-radius: 50%;
  &:hover, &.isSelected {
    color: ${activeTheme.invertFontColor};
    background-color: ${activeTheme.primaryColor};
    text-decoration-color: ${activeTheme.invertFontColor};
  }
`;

const DayUnaivalable = styled(CustomDay)`
  opacity: .4;
  background-color: transparent;
  pointer-events: none;
  &,
  &:hover {
    color: ${activeTheme.legendBorderGray};
  }
`;

const DayFullStyle = styled(DayUnaivalable)<IStyle>`
  border: 1px dashed ${activeTheme.legendBorderGray};
`;

const DayClosedStyle = styled(DayUnaivalable)<IStyle>`
  border: 1px solid ${activeTheme.legendBorderGray};
`;

const ArrowLeft = styled(ArrowBackIosIcon)`
  position: absolute;
  top: 22px;
  left: 10px;
`;

const ArrowRight = styled(ArrowForwardIosIcon)`
  position: absolute;
  top: 22px;
  right: 10px;
`;

const Calendar = () => {
    const { t, i18n } = useTranslation();
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(useLocation().state);
    const [focused, setFocused] = useState(true);
    const [dailyAvailabilities, setDailyAvailabilities] = useState(null);
    const [dailyEngine, setDailyEngine] = useState(null);
    const last_booking_date = (moduleSettings.last_booking_date === "-1") ? moment().add(2, 'years') : moduleSettings.last_booking_date
    const selectedDate = selectionStore.date;
    const [selectionStoreFilter, setSelectionStoreFilter] = useState(null);
    const throwError = useErrorHandler();

    useEffect(() => {
       getSelectionStoreChangesService().registerListener("calendar", ["calendar_date", "period", "area"], updateFilters);

       return () => {
            getSelectionStoreChangesService().unregisterListener("calendar");
        };
    }, []);


    useEffect(() => {
        setLoading(true);
        setDailyAvailabilities(null);

        const subscription = getDailyAvailabilities()
         .subscribe((dailyLightInfoResponseData: DailyLightInfoResponse) => {
              setDailyAvailabilities(Object.assign({}, ...dailyLightInfoResponseData.daily_availabilities.map((daily_avail) => ({[daily_avail.date]:daily_avail.status}))));
              setDailyEngine(Object.assign({}, ...dailyLightInfoResponseData.daily_availabilities.map((daily_avail) => ({[daily_avail.date]:daily_avail.engine_mode}))));
              setLoading(false);
          }
          , throwError);
        return () => {
            subscription.unsubscribe();
        }; 
    }, [selectionStoreFilter, throwError]);

    if (redirect) {
      const engineMode = dailyEngine[selectionStore.date.format('YYYY-MM-DD')];
        if(EngineMode.capacity === engineMode){
          selectionStore.area = null;
        }
        return <Redirect to={{
          pathname:buildRouteWithParams(config.routes.selectionTime),
          state:{engineMode: engineMode}
        }
        }/>;
    }

    const updateFilters = () => {
      setSelectionStoreFilter([selectionStore.calendarDate, selectionStore.period, selectionStore.area]);
    }

    const onFocusChange = () => {
        setFocused(true)
    };

    const onNextMonth =  () => {
        setError(false);
        const newDate = selectionStore.calendarDate.clone().add(1, 'months').startOf('month')
        if (selectionStore.calendarDate !== newDate) {
            selectionStore.calendarDate = newDate;
            getSelectionStoreChangesService().callListeners("calendar_date");
        }
    };

    const onPrevMonth = () => {
        setError(false);
        const newDate = selectionStore.calendarDate.clone().subtract(1, 'months').startOf('month')
        if (selectionStore.calendarDate !== newDate) {
            selectionStore.calendarDate = newDate;
            getSelectionStoreChangesService().callListeners("calendar_date");
        }
    };

    const onDateChange = (momentDate: any) => {
        const momentDiff = momentDate.diff(DATE_NOW);
        const endTimeDiff = momentDate.diff(last_booking_date);
        if (momentDiff > 0 && endTimeDiff < 0) {
            if (selectionStore?.date?.format("D MMMM YYYY") !== momentDate.format("D MMMM YYYY")) {
                selectionStore.date = momentDate;
                navigationStore.addDateToHistory();
            }
            setRedirect(true);
        }
    };

    const getDay = (currentDate: Moment): ReactNode => {
        const status = dailyAvailabilities[currentDate.format('YYYY-MM-DD')];
        const today = currentDate.isSame(DATE_NOW, "day");
        const isSelected = selectedDate && currentDate.isSame(selectedDate, "day");
        switch (status) {
          case DayStatus.waiting:
              return <CustomDay today={today} className={isSelected ? "isSelected" : ""}>
                        {currentDate.format("D")}
                        <RoundedIcon icon="PauseIcon" />
                    </CustomDay>;

          case DayStatus.full:
          case DayStatus.unavailable:
              return <DayFullStyle today={today}>{currentDate.format("D")}</DayFullStyle>;

          case DayStatus.closed:
              return <DayClosedStyle today={today}>{currentDate.format("D")}</DayClosedStyle>;

          case DayStatus.available:
            return <CustomDay today={today} className={isSelected ? "isSelected" : ""}>{currentDate.format("D")}</CustomDay>;

          default:
              return <DayUnaivalable today={today}>{currentDate.format("D")}</DayUnaivalable>;
      };
    };

    const getIsDayBlocked = (day: Moment): boolean => {
      const momentDiff = day.diff(DATE_NOW);
      const status = dailyAvailabilities[day.format('YYYY-MM-DD')];
        if (momentDiff < 0 || status === DayStatus.full || status === DayStatus.closed || status === DayStatus.unavailable)
          return true;
    };

    const renderDayContents = (currentDate: Moment): ReactNode => {
        const momentDiff = currentDate.diff(DATE_NOW);
        const endTimeDiff = currentDate.diff(last_booking_date);
        if (momentDiff < 0 || endTimeDiff > 0) {
            return <DayUnaivalable today={false}>{currentDate.format("D")}</DayUnaivalable>;
        }else{
            return getDay(currentDate);
        }
    };

    const hasPrevMonth =  () : boolean => {
      const startOfMonth = selectionStore.calendarDate.clone();
      return (startOfMonth.diff(DATE_NOW)) > 0;
    };

    const hasNextMonth = () : boolean => { 
      const endOfMonth = selectionStore.calendarDate.clone().endOf("month");
      return (endOfMonth.diff(last_booking_date)) <= 0;
    };

    const renderMonthElement = ({month}) => {
      return capitalize(moment(month).locale(i18n.language).format("MMMM YYYY"))
    }

    const renderCalendar = () => {
      return (<CalendarWrapper>
         {error && <ErrorMessage error={error["error"]} status={"error"}/>}
         <DayPickerSingleDateController
            onDateChange={onDateChange}
            onFocusChange={onFocusChange}
            focused={focused}
            date={selectionStore.calendarDate}
            numberOfMonths={NUMBER_OF_MONTHS}
            navNext={<ArrowRight/>}
            navPrev={<ArrowLeft/>}
            firstDayOfWeek={1}
            initialVisibleMonth={() => selectionStore.calendarDate}
            renderDayContents={renderDayContents}
            isDayBlocked={getIsDayBlocked}
            renderMonthElement={renderMonthElement}
            renderWeekHeaderElement={day => capitalize(t(day.toLowerCase()))}
            onPrevMonthClick={onPrevMonth}
            onNextMonthClick={onNextMonth}
            noNavPrevButton={!hasPrevMonth()}
            noNavNextButton={!hasNextMonth()}
            hideKeyboardShortcutsPanel
        />
        </CalendarWrapper>);
    };

    const renderLoading = () => {
      return <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
    };

    return <CalendarWrapper>
        { !loading && dailyAvailabilities !== null ? renderCalendar() : renderLoading() }
    </CalendarWrapper>;
};
export const CalendarLayout = memo(Calendar);
