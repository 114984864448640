import { AxiosRequestConfig } from "axios";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { getAxios } from "./axios.service";
import { getApiErrorFactory } from './api-errors'
import { Endpoint } from "../endpoints/BaseEndpoint";

const Axios = getAxios();

class HttpService {
    public getParams = (params: any) => {
        let queryString = null;
        const keys = Object.keys(params);
        for (const key of keys) {
            if (queryString) {
                queryString += "&";
            }
            queryString = queryString ? queryString + key + "=" + params[key] : key + "=" + encodeURIComponent(params[key]);
        }
        return "?" + queryString;
    };

    public get(endPoint: Endpoint, params: any = null): Observable<any> {
        let mergedParams = "";
        if (params) {
            mergedParams = this.getParams(params);
        }
        const options: AxiosRequestConfig | any = {
            store: this,
            method: "GET",
            url: `${endPoint.getEndpoint()}${mergedParams}`,
            headers: endPoint.getHeaders()
        };
        return Axios.request(options).pipe(
            map((response: any) => {
                return response.data;
            }),
            catchError((err) => {
                return new Observable((observer) => {
                    observer.error(getApiErrorFactory().getApiError(err));
                    observer.complete();
                });
            }),
        );
    }

    public post(endPoint: Endpoint, data: any): Observable<any> {
        const options: AxiosRequestConfig = {
            method: "POST",
            url: endPoint.getEndpoint(),
            headers: endPoint.getHeaders(),
            data
        };
        return Axios.request(options).pipe(
            map((resp) => {
                return resp.data;
            }),
            catchError((err) => {

                return new Observable((observer) => {
                    observer.error(getApiErrorFactory().getApiError(err));
                    observer.complete();
                });
            }),
        );
    }

    public put(endPoint: Endpoint, data: any): Observable<any> {
        const options: AxiosRequestConfig = {
            method: "PUT",
            url: endPoint.getEndpoint(),
            headers: endPoint.getHeaders(),
            data
        };
        return Axios.request(options).pipe(
            map((resp) => {
                return resp.data;
            }),
            catchError((err) => {

                return new Observable((observer) => {
                    observer.error(getApiErrorFactory().getApiError(err));
                    observer.complete();
                });
            }),
        );
    }

    public delete(endPoint: Endpoint, data: any): Observable<any> {
        const options: AxiosRequestConfig = {
            method: "DELETE",
            url: endPoint.getEndpoint(),
            headers: endPoint.getHeaders(),
            data
        };
        return Axios.request(options).pipe(
            map((resp) => {
                return resp.data;
            }),
            catchError((err) => {

                return new Observable((observer) => {
                    observer.error(err);
                    observer.complete();
                });
            }),
        );
    }
}

let httpService: HttpService;

export const getHttpService = (): HttpService => {
    if (!httpService) {
        httpService = new HttpService();
    }
    return httpService;
};
