import React, {memo} from "react";
import styled from "styled-components";
import {ThemeManager} from "../../theme-manager/theme-manager";

const activeTheme = ThemeManager.getTheme();

interface IProps {
    text: string;
		status?: string;
}

interface IStyled {
	status: string;
}

const handleBackgroundColor = status => {
  switch (status) {
    case "error":
      return "#f5e0df";
		default:
      return activeTheme.informationTextBackgroundColor;
  }
};

const handleColorText = status => {
  switch (status) {
    case "error":
      return "#e74c3c";
    case "warning":
      return "#f57c00";
		default:
      return activeTheme.textColorGray;
  }
};

const InformationStyle = styled.div<IStyled>`
	position: relative;
	display: flex;
	width: 100%;
	background-color: ${props => handleBackgroundColor(props.status)};
	color: ${props => handleColorText(props.status)};
	justify-content: center;
	align-items: center;
	text-align: center;
	border-radius: 5px;
	padding: 15px 22px;
	margin-top: ${props => props.status ? "20px" : 0};
	margin-bottom: ${props => props.status ? "20px" : "35px"};

	&::before {
		content: " ";
		position: absolute;
		left: -5px;
		top: 10px;
		width: 15px;
		height: 15px;
		transform: rotate(45deg);
		background-color: ${props => handleBackgroundColor(props.status)};
	}
`;

const Information = (props: IProps) => {
    const mappedText = props.text.split('\r\n').join('</br>');
    return <InformationStyle dangerouslySetInnerHTML={{__html: mappedText}} status={props.status}/>;
};
export const InformationText = memo(Information);
