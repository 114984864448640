
export interface ApiError extends GolBaseError {
    status: number;
    code: ApiErrorCode
    data?: ApiErrorBody
    response?: any; 
}

export interface GolBaseError{
    getMessage(): string
}

export interface ApiErrorBody {
    message: string
    code?: string
}

export interface ApiErrorResponse {
    error: ApiErrorBody
}

export interface AccessApiErrorBody extends ApiErrorBody {
    partner_name: string
}


export enum ApiErrorCode {
    unknown = "unknown",
    connection_error = "connection_error",
    restaurant_id_missing = "restaurant_id_missing",
    restaurant_blank = "restaurant_blank",
    no_access = "no_access",
    access_denied = "access_denied",
    partnership_disabled = "partnership_disabled",
    partnership_not_yet_created = "partnership_not_yet_created",
    capture_failed = "capture_failed",
    bad_request = "bad_request",
    unfound = "unfound",
    resource_validation_error = "resource_validation_error",
    pending_booking_unfound = "pending_booking_unfound",
    menu_unfound = "menu_unfound",
    runtime_commercial_unfound= "runtime_commercial_unfound",
    pending_booking_creation_failed="pending_booking_creation_failed",
    payment_refused="payment_refused",
    setup_intent_invalid="setup_intent_invalid",
    stripe_customer_creation_failed="stripe_customer_creation_failed"
}

export interface AccessDeniedError extends ApiError {}

export enum ErrorType{
    error = "error",
    warning = "warning"
}

