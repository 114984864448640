import React, {memo} from "react";
import {InformationText} from "../information-text/information-text";

interface IProps {
  error: string;
  status?: string;
}

const ErrorMessageView = (props: IProps) => {
    return <InformationText text={props.error} status={props.status} />;
};

export const ErrorMessage = memo(ErrorMessageView);