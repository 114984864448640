import React, {memo} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {ITimeSlot, TimeSlot} from "../time-slot/time-slot";

interface IProps {
    sectionTitleKey: string;
    sectionTimeSlots: Array<ITimeSlot>;
    onSetIsLoading: any;
}

export const TimeSectionStyle = styled.div`
  font-size: 14px;
`;

export const TimeSectionTitle = styled.div`
  margin-bottom: 12px;
  font-weight: bold;
`;

const Time = (props: IProps) => {
    const {t} = useTranslation();

    const timeSlots = props.sectionTimeSlots.map((timeSlot: ITimeSlot) => {
        return <TimeSlot
            icons={{
                hasWaitingList: timeSlot.icons.hasWaitingList,
                hasOffer: timeSlot.icons.hasOffer,
                hasPrepayment: timeSlot.icons.hasPrepayment
            }}
            hour={timeSlot.hour}
            key={timeSlot.hour}
            onSetIsLoading={props.onSetIsLoading}
            onTimeSelect={timeSlot.onTimeSelect}
            isSelected={timeSlot.isSelected}
        />;
    });
    return <TimeSectionStyle>
        <TimeSectionTitle>{t("instabook.periods."+props.sectionTitleKey)}</TimeSectionTitle>
        <div className="timeSlots">
            {timeSlots}
        </div>
    </TimeSectionStyle>;
};
export const TimeSection = memo(Time);
