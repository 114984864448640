import React, {memo, useEffect, useState} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {Dropdown} from "../../dropdown/dropdown";
import {Area, Periods} from "../../../types";
import {selectionStore} from "../../../stores/selection.store";
import {stringArrayToDropdown, stringToDropdown} from "../../dropdown/dropdown-transform";
import {getFiltersService} from "../../../services/filters.service";
import {moduleSettings} from "../../../stores/module-settings.store";

interface ICalendarFilters {
    alignPosition: CalendarFiltersPosition,
    onDropdownItemChanged?: any,
    areas: Array<Area>,
    periods: Array<Periods>
}

export enum CalendarFiltersPosition {
    start = "start",
    center = "center"
}

const FilterStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: ${props => props.theme.alignPosition};
  align-items: center;
  text-align: center;
  margin-bottom: 30px;

  @media screen and (min-width: 981px),
  (min-width: 481px) and (max-width: 768px) {
    > * {
      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }

  @media screen and (max-width: 980px) and (min-width: 769px),
  (max-width: 480px) {
    > * {
      flex: 0 0 100%;
      margin-bottom: 10px;
    }
  }
`;

const Filters = (props: ICalendarFilters) => {
    // eslint-disable-next-line
    const {t} = useTranslation();

    const periodChanged = (value: any) => {
        if (selectionStore.period !== value) {
            selectionStore.period = value;
            if (props.onDropdownItemChanged) {
                props.onDropdownItemChanged(value);
            }
        }
    };

    const areaChanged = (value: any) => {
        if (selectionStore.area !== value) {
            selectionStore.area = value;
            if (props.onDropdownItemChanged) {
                props.onDropdownItemChanged(value);
            }
        }
    };

    const [periodsOptions, setPeriodsOptions] = useState<any>();
    const [areasOptions, setAreasOptions] = useState<any>();
    useEffect(() => {
        const areas = props.areas && props.areas.length ? props.areas : null;
        const periods = props.periods && props.periods.length ? props.periods : moduleSettings.periods;
        setPeriodsOptions(stringArrayToDropdown(periods, selectionStore.period, "periods."));
        setAreasOptions(areas ? getFiltersService().mapAreaToTranslation(areas, selectionStore.area) : null);
    }, [props]);
    return <FilterStyle theme={props}>
        {periodsOptions ? <Dropdown
            options={periodsOptions}
            placeholderOption={stringToDropdown("all", null, "periods.")}
            placeholderSelectable={true}
            onItemClick={periodChanged}
        /> : ""}
        {areasOptions ? <Dropdown
            options={areasOptions}
            placeholderOption={stringToDropdown("all", null, "rooms.")}
            placeholderSelectable={true}
            onItemClick={areaChanged}
        /> : ""}
    </FilterStyle>;
};

export const TimeFilters = memo(Filters);
