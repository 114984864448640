import { IThemeProps, ButtonStyle, HeaderStyle } from "../types";
import { Theme } from "./Theme";

let currentTheme: Theme;

const keiTheme: IThemeProps = {
    primaryColor: "#660032",
    headerBackgroundColor:  "#660032",
    headerStyle: HeaderStyle.text,
    reservationSuccess: "#660032",
    buttonStyle: ButtonStyle.text,
}
export class ThemeManager {
    public static getTheme(): Theme {
        if (!currentTheme) {
            currentTheme = new Theme(keiTheme);
        }
        return currentTheme;
    }
}

