import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {AvailableCommercials, Commercial, CommercialKeys} from "../../../types";
import {ThemeManager} from "../../../theme-manager/theme-manager";
import {CommercialsItem} from "../../commercials/commercials-item";
import {getTagManagerService} from "../../../services/tag-manager.service";
import {moduleSettings} from "../../../stores/module-settings.store";
import {getCommercialsService} from "../../../services/commercials.service";
import { AcceptedPositions, ContinueButton } from "../../continue-button/continue-button";


const activeTheme = ThemeManager.getTheme();

const CommercialsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CommercialItems = styled.div`
  width: 100%;
`;

const CommercialsTitle = styled.div`
  color: ${activeTheme.textColorGray};
  margin-bottom: 20px;
`;

export interface ISelectionCommercialsDisplay {
    isFirstStep?: boolean;
    commercials: Array<Commercial>;
    selectionSkipped: () => void;
    commercialSelected: () => void;
    eventsContinue: () => void;
    commercialsKey: string;
    commercialsType: AvailableCommercials;
}

const CommercialsDisplay = (props: ISelectionCommercialsDisplay) => {
    const {t} = useTranslation();
    const commercialsService = getCommercialsService();
    const commercialSelected = () => {
        props.commercialSelected();
    };
    const commercials = props.commercials.map((item: Commercial) => {
        return <CommercialsItem key={item.id}
                                commercial={item}
                                commercialSelected={commercialSelected}
                                isFirstStep={props.isFirstStep}/>;
    });
    const events = commercialsService.hasEvents() ? commercialsService.events.map((ev: Commercial) => {
        return <CommercialsItem key={ev.id}
                                commercial={ev}
                                commercialSelected={() => {
                                }}/>;
    }) : [];
    const restaurantId = moduleSettings.getRestaurantId();
    getTagManagerService(restaurantId).pushTags("commercials", {first_step: props.isFirstStep});
    return <div>
        {props.commercialsType === AvailableCommercials.menus ?
            <CommercialsWrapper>
                {events.length ?
                    <><CommercialsTitle>{t("instabook." + CommercialKeys.events + ".title")}</CommercialsTitle>
                        <CommercialItems>
                            {events}
                            {!commercialsService.hasMenus() ?
                                <ContinueButton buttonTextKey={t("button.continue")}
                                                position={AcceptedPositions.left}
                                                onClick={props.eventsContinue} /> : ""}
                        </CommercialItems></>
                    : ""}
                {commercialsService.hasMenus() ?
                    <><CommercialsTitle>
                        {t("instabook." + props.commercialsKey + ".info")}
                    </CommercialsTitle>
                        <CommercialItems>
                            {commercials}
                            <ContinueButton buttonTextKey={t("instabook." + props.commercialsKey + ".skip")}
                                            position={AcceptedPositions.left}
                                            onClick={props.selectionSkipped} />
                        </CommercialItems></> : ""}
            </CommercialsWrapper>
            :
            <CommercialsWrapper>
                <CommercialsTitle>
                    {t("instabook." + props.commercialsKey + ".title")}
                </CommercialsTitle>
                <CommercialItems>
                    {commercials}
                    <ContinueButton buttonTextKey={t("instabook." + props.commercialsKey + ".skip")}
                                    position={AcceptedPositions.left}
                                    onClick={props.selectionSkipped} />
                </CommercialItems>
            </CommercialsWrapper>}
    </div>;
};
export const SelectionCommercialsDisplay = memo(CommercialsDisplay);
