import {commercialsStore} from "../stores/commercials.store";
import {selectionStore} from "../stores/selection.store";
import {AvailableCommercials, CommercialKeys, SelectionCommercial} from "../types";

class Commercials {
    public get menus(): Array<any> {
        if (commercialsStore.menus.length) {
            return commercialsStore.menus;
        }
        return [];
    }

    public get offers(): Array<any> {
        if (commercialsStore.offers.length) {
            return commercialsStore.offers;
        }
        return [];
    }

    public get events(): Array<any> {
        if (commercialsStore.events.length) {
            return commercialsStore.events;
        }
        return [];
    }

    public hasMenus(): boolean {
        return !!this.menus.length;
    }

    public hasOffers(): boolean {
        return !!this.offers.length;
    }

    public hasEvents(): boolean {
        return !!this.events.length;
    }

    public hasMenuSelected(): boolean {
        return selectionStore.getSelectedCommercial(AvailableCommercials.menus);
    }

    public hasOfferSelected(): boolean {
        return selectionStore.getSelectedCommercial(AvailableCommercials.offers);
    }

    public shouldDisplayMenus(): boolean {
        return this.hasMenus() && !this.hasMenuSelected();
    }

    public shouldDisplayOffers(): boolean {
        return this.hasOffers() && !this.hasOfferSelected();
    }

    public shouldDisplayEvents(): boolean {
        return this.hasEvents();
    }

    public shouldDisplayCommercial(): boolean {
        return this.shouldDisplayMenus() ||
            this.shouldDisplayOffers() ||
            this.shouldDisplayEvents();
    }

    public getCommercialsByType(commercialsType: AvailableCommercials): SelectionCommercial | null {
        switch (commercialsType) {
            case AvailableCommercials.offers:
                return {
                    type: AvailableCommercials.offers,
                    commercials: this.offers,
                    commercialsKey: CommercialKeys.offers
                };
            case AvailableCommercials.menus:
                return {
                    type: AvailableCommercials.menus,
                    commercials: this.menus,
                    commercialsKey: CommercialKeys.menus
                };
            default:
                return null;
        }
    }

    public getCommercials(): SelectionCommercial | null {
        console.log('get');
        if (this.shouldDisplayOffers()) {
            console.log('offers?');
            return this.getCommercialsByType(AvailableCommercials.offers);
        }
        if (this.shouldDisplayMenus() || this.shouldDisplayEvents()) {
            console.log('menus? events?');
            return this.getCommercialsByType(AvailableCommercials.menus);
        }
        console.log('null');
        return null;
    }

    public nextStepCommercial(currentCommercial?: AvailableCommercials): AvailableCommercials | null {
        switch (currentCommercial) {
            case undefined: {
                if (this.shouldDisplayOffers()) {
                    return AvailableCommercials.offers;
                }
                if (this.shouldDisplayMenus() || this.shouldDisplayEvents()) {
                    return AvailableCommercials.menus;
                }
                break;
            }
            case AvailableCommercials.offers: {
                if (this.shouldDisplayMenus() || this.shouldDisplayEvents()) {
                    return AvailableCommercials.menus;
                }
                break;
            }
            default:
                break;
        }
        return null;
    }

    public mapCommercialTypeToRoute(commercialType: string): AvailableCommercials {
        if (commercialType === "offer") {
            return AvailableCommercials.offers;
        } else {
            return AvailableCommercials.menus;
        }
    }
}

let commercialsService: Commercials;

export const getCommercialsService = (): Commercials => {
    if (!commercialsService) {
        commercialsService = new Commercials();
    }
    return commercialsService;
};
