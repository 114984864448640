import React, {memo, useEffect, useState} from "react";
import {ThemeManager} from "../../../theme-manager/theme-manager";
import {CommercialTitle} from "../commercial-title";
import {CommercialText} from "../commercial-text";
import {Commercial} from "../../../types";
import styled from "styled-components";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {CommercialsMenuModal} from "./commercials-menu-modal";
import {selectionStore} from "../../../stores/selection.store";

const activeTheme = ThemeManager.getTheme();

interface ICommercialsMenuItemProps {
    commercial: Commercial;
    menuCountChanged: (newValue: number) => void;
    menuCount: number;
    isFirstStep?: boolean;
}

const CommercialMenu = styled.div`
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 12px 24px 36px;
  margin-bottom: 22px;
  cursor: pointer;
`;

const CommercialMenuActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  height: 80%;
  bottom: 10px;
  right: 10px;
`;

const CommercialMenuPreview = styled.div`
  line-height: 1;
  margin-left: 20px;
`;

const CommercialMenuCounter = styled.div`
  color: ${activeTheme.textColor};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  height: 24px;

  .hidden {
    display: none;
  }

  * {
    padding: 0 5px;
  }
`;

const ShowMoreIcon = styled(VisibilityIcon)`
  color: ${activeTheme.disabledColor};
`;

const Item = (props: ICommercialsMenuItemProps) => {
    const minimumDishes = props.commercial.minimum_dishes ?? 1;
    const maximumDishes = props.commercial.maximum_dishes ?? selectionStore.guests;
    const [menusNumber, setMenusNumber] = useState(props.menuCount);
    const [isFullMenuDisplayed, setIsFullMenuDisplayed] = useState(false);

    const keyPressed = (ev: KeyboardEvent) => {
        if (ev.key.toLowerCase() === "escape" && isFullMenuDisplayed) {
            closePreview(ev);
        }
    };

    useEffect(() => {
            window.addEventListener("keydown", keyPressed);
            return () => {
                window.removeEventListener("keydown", keyPressed);
            };
        },
    );
    const lowerMenusNumber = (ev: any) => {
        ev.stopPropagation();
        if (menusNumber >= minimumDishes) {
            setMenusNumber(menusNumber - 1);
            props.menuCountChanged(menusNumber - 1);
        }
    };
    const raiseMenusNumber = (ev: any) => {
        ev.stopPropagation();
        if (menusNumber < maximumDishes) {
            setMenusNumber(menusNumber + 1);
            props.menuCountChanged(menusNumber + 1);
        }
    };
    const previewMenu = (ev: any) => {
        ev.stopPropagation();
        setIsFullMenuDisplayed(true);
    };
    const closePreview = (ev: any) => {
        ev.stopPropagation();
        setIsFullMenuDisplayed(false);
    };
    return <div>
        <CommercialMenu>
            <CommercialTitle title={props.commercial.name}/>
            <CommercialText text={props.commercial.description}/>
            <CommercialMenuActions>
                <CommercialMenuPreview onClick={previewMenu}>
                    <ShowMoreIcon/>
                </CommercialMenuPreview>
                {!props.isFirstStep ? <CommercialMenuCounter>
                    <div onClick={lowerMenusNumber}>
                        -
                    </div>
                    <div>
                        {menusNumber}
                    </div>
                    <div onClick={raiseMenusNumber}>
                        +
                    </div>
                </CommercialMenuCounter> : ""}
            </CommercialMenuActions>
        </CommercialMenu>
        {isFullMenuDisplayed ?
            <CommercialsMenuModal
                closeModal={closePreview}
                title={props.commercial.name}
                text={props.commercial.description}
            /> : ""}
    </div>;
};

export const CommercialsMenuItem = memo(Item);
