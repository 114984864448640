import React, {memo, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {TextField} from "@material-ui/core";
import RedeemIcon from "@material-ui/icons/Redeem";
import {CheckCircle} from "@material-ui/icons";
import {ThemeManager} from "../../../theme-manager/theme-manager";
import {checkCouponCode} from "../../../requests/pending-booking.requests";
import { config } from "../../../config";
import {pendingBookingStore} from "../../../stores/pending-booking.store";
import {Loader} from "../../loader/loader";
import {AcceptedPositions, ContinueButton} from "../../continue-button/continue-button";
import { buildRouteWithParams } from "../../../helpers/build-route-with-params";

const activeTheme = ThemeManager.getTheme();

const DiscountWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

const DiscountWrapperLabel = styled.div`
  color: ${activeTheme.disabledColor};
  flex: 0 0 100%;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 10px;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 250px;
`;

const TextInput = styled(TextField)`
  width: 100%;
`;

const DiscountIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  display: flex;
  align-items: center;
`;

const CodeValid = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: ${activeTheme.reservationSuccess};
`;

const Coupon = () => {
    const {t} = useTranslation();
    const [code, setCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [codeShouldBeCheck, setCodeShouldBeCheck] = useState(false);
    const [isCodeChecked, setIsCodeChecked] = useState(false);
    const [isCodeValid, setIsCodeValid] = useState<boolean>();
    const [messageError, setMessageError] = useState("");

    const couponCodeChanged = (ev: any) => {
        const newCode = ev.target.value;
        if (code === newCode) {
            return;
        }
        setCode(newCode);
        setCodeShouldBeCheck(true);
        setIsCodeChecked(false);
    };

    const checkCouponCodeOnClick = () => {
        if (!codeShouldBeCheck) {
          return;
        }
        setIsLoading(true);
        setIsCodeChecked(false);
        setCodeShouldBeCheck(false);
        const subscription = checkCouponCode(code).subscribe({
          next: (resp: any) => {
            pendingBookingStore.pendingBooking = resp;
            setIsCodeValid(Object.keys(resp.voucher_infos).length !== 0);
            setIsCodeChecked(true);
            setIsLoading(false);
            setMessageError("");
          },
          error: (error) => {
            setIsCodeChecked(true);
            setIsCodeValid(false)
            setIsLoading(false);
            setMessageError(error.response.data["error"]["voucher_details"][0]);
          }
        });
        return () => {
          subscription.unsubscribe();
        };
    };

    let buttonText = codeShouldBeCheck && code ? "instabook.gift_code.validate_gift_code" : "instabook.gift_code.ignore_gift_code";
    if (isCodeChecked) {
      buttonText = "button.continue";
    };

    return <DiscountWrapper>
        {isLoading ? <Loader/> : ""}
        <DiscountWrapperLabel>{t("instabook.gift_code.enter_your_discount_code")}</DiscountWrapperLabel>
        <InputWrapper>
            <TextInput error={isCodeChecked && !isCodeValid}
                       defaultValue={code}
                       label="Code"
                       variant="outlined"
                       helperText={messageError}
                       onChange={couponCodeChanged}/>
            <DiscountIcon>
                <RedeemIcon/>
            </DiscountIcon>
        </InputWrapper>
        {isCodeValid ? <CodeValid><CheckCircle/></CodeValid> : ""}
        <ContinueButton onClick={checkCouponCodeOnClick}
                        buttonTextKey={buttonText}
                        position={AcceptedPositions.left}
                        route={!code || isCodeChecked ? buildRouteWithParams(config.routes.confirmation) : ""}/>
    </DiscountWrapper>;
};

export const DetailsCoupon = memo(Coupon);
