import {getHttpService} from "../services/http.service";
import {DailyAvailabilitiesEndpoint} from "../endpoints/DailyAvailabilitiesEndpoint";
import {DailyLightInfoResponse} from "../types";
import {Observable} from "rxjs";
import {selectionStore} from "../stores/selection.store";


//startDate in format "yyyy-M-D"
const httpService = getHttpService();
export const getDailyAvailabilities = () : Observable<DailyLightInfoResponse> => {
    const endpoint = new DailyAvailabilitiesEndpoint();
    const startDate = selectionStore.calendarDate.clone().format('YYYY-MM-DD');
    const persons = selectionStore.guests.toString();
    const selectedPeriod = selectionStore.period;
    const selectedArea = selectionStore.area;


    const queryParams = [{
        queryParamName: "start_date",
        queryParamValue: startDate
    }, {
        queryParamName: "persons",
        queryParamValue: persons
    }];

    if (selectedPeriod && selectedPeriod !== "all"){
        queryParams.push({
            queryParamName: "period",
            queryParamValue: selectedPeriod
        });
    }

    if (selectedArea && selectedArea !== "all"){
        queryParams.push({
            queryParamName: "area_id",
            queryParamValue: selectedArea
        });
    }

    endpoint.addQueryParams(queryParams);
    return httpService.get(endpoint);
}