import React, {memo} from "react";
import {InformationText} from "../information-text/information-text";
import {pendingBookingStore} from "../../stores/pending-booking.store";
import {useTranslation} from "react-i18next";

const Message = () => {
    const {i18n} = useTranslation();
    const pendingBookingMessage = pendingBookingStore.getPendingBookingMessage(i18n.language);
    return <div>{pendingBookingMessage ? <InformationText text={pendingBookingMessage}/> : ""}</div>;
};
export const PendingBookingMessage = memo(Message);
