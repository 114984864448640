import React, {memo, useEffect, useState} from "react";
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {moduleSettings} from "../../../stores/module-settings.store";
import {setDangerouslyInnerHtml} from "../../../helpers/set-inner-html";
import {AcceptedPositions, ContinueButton} from "../../continue-button/continue-button";
import {ThemeManager} from "../../../theme-manager/theme-manager";
import {getTagManagerService} from "../../../services/tag-manager.service";
import {selectionStore} from "../../../stores/selection.store";
import {useHistory, useLocation} from "react-router-dom";
import {buildRouteWithParams} from "../../../helpers/build-route-with-params";
import {config} from "../../../config";
import {navigationStore} from "../../../stores/navigation.store";
import {Booking, BookingState, PendingBookingContact} from "../../../types";
import {updatePendingBookingRequest, commitPendingBooking} from "../../../requests/pending-booking.requests";
import {pendingBookingStore} from "../../../stores/pending-booking.store";
import {CountrySelect} from "../../country-select/country-select";
import { useErrorHandler} from 'react-error-boundary'
import { ErrorMessage } from "../../errors/message-error-view";
import {bookingStore} from "../../../stores/booking.store";
import { Countries } from "../../../assets/json/countries";
import { getLanguageSwitcherService } from "../../../services/language-switcher.service";
import { capitalize } from "../../../utils";

const activeTheme = ThemeManager.getTheme();

const ContactFormWrapper = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  justify-content: center;
  margin-top: 30px;

  &.disabled {
    pointer-events: none;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormElementPadding = styled.div`
  padding: 0 10px;
  margin-bottom: 10px;
`;

const FormElementPaddingLeft = styled.div`
  padding-left: 10px;
  margin-bottom: 10px;
`;

const FormElementPaddingRight = styled.div`
  padding-right: 10px;
  margin-bottom: 10px;
`;

const Name = styled(FormElementPadding)`
  flex: 0 0 50%;
  max-width: 50%;
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    min-width: 100%;
    max-width: 100%;
  }
`;

const Prefix = styled(FormElementPadding)`
  flex: 0 0 35%;
  max-width: 35%;
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    min-width: 100%;
    max-width: 100%;
  }
`;

const PhoneNumber = styled(FormElementPadding)`
  flex: 0 0 65%;
  max-width: 65%;
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    min-width: 100%;
    max-width: 100%;
  }
`;

const EmailWithBirthday = styled(FormElementPaddingRight)`
  flex: 0 0 75%;
  max-width: 75%;
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    min-width: 100%;
    max-width: 100%;
    padding-right: 0px;
  }
`;

const Birthday = styled(FormElementPaddingLeft)`
    flex: 0 0 25%;
    min-width: 25%;
    max-width: 25%;

    & > * {
        width: 100%;
    }

    input {
        height: 19px;
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        flex: 0 0 100%;
        min-width: 100%;
        max-width: 100%;
        padding-left: 0;
    }
`;

const FullElement = styled(FormElementPadding)`
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const RadioWrapper = styled.div`
  flex: 0 0 100%;
  padding: 0 10px;
`;

const FormControlStyledLabel = styled(FormControlLabel)`
  a {
    color: ${activeTheme.primaryColor};
  }

  &.error {
    .MuiCheckbox-root {
      color: ${activeTheme.error};
    }
  }
`;

const CustomErrorField = styled(TextField)`
  &.error {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${activeTheme.error};
    }

    .MuiOutlinedInput-root:not(.Mui-focused) {
      color: ${activeTheme.error};
    }
  }

`;

const ContactForm = () => {
    const { t, i18n } = useTranslation();
    const hasPrepayment = pendingBookingStore.getPendingBookingPrepayment();
    const customFields = moduleSettings.getContactFormCustomFields();
    const partner = capitalize(customFields.partner_name);
    const history = useHistory();

    const [terms, setTerms] = useState("");
    const [policy, setPolicy] = useState("");
    const [firstName, setFirstName] = useState(pendingBookingStore.pendingBookingContact ? pendingBookingStore.pendingBookingContact.firstname : "");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastName, setLastName] = useState(pendingBookingStore.pendingBookingContact ? pendingBookingStore.pendingBookingContact.lastname : "");
    const [lastNameError, setLastNameError] = useState("");
    const [defaultCountry, setDefaultCountry] = useState(null);
    const [, setDefaultCountryError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(pendingBookingStore.pendingBookingContact ? pendingBookingStore.pendingBookingContact.mobile_phone : "");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [email, setEmail] = useState(pendingBookingStore.pendingBookingContact ? pendingBookingStore.pendingBookingContact.email : "");
    const [emailError, setEmailError] = useState("");
    const [birthday, setBirthday] =  useState(pendingBookingStore.pendingBookingContact ? pendingBookingStore.pendingBookingContact.birthday : "");
    const [company, setCompany] = useState(pendingBookingStore.pendingBookingContact ? pendingBookingStore.pendingBookingContact.company : "");
    const [termsChecked, setTermsChecked] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [message, setMessage] = useState(pendingBookingStore.pendingBookingContact ? pendingBookingStore.pendingBookingContact.message : "");
    const [allergies, setAllergies] = useState(pendingBookingStore.pendingBookingContact ? pendingBookingStore.pendingBookingContact.allergies : "");
    const [allergiesTermsChecked, setAllergiesTermsChecked] = useState(false);
    const [allergiesTermsError, setAllergiesTermsError] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [errorMsg, ] = useState(useLocation().state);

    const throwError = useErrorHandler();

    const onFirstNameChange = (ev: any) => {
        updateField(ev.target.value, setFirstName, setFirstNameError);
    };
    const onLastNameChange = (ev: any) => {
        updateField(ev.target.value, setLastName, setLastNameError);
    };
    const onCountryChange = (value: any) => {
        updateField(value, setDefaultCountry, setDefaultCountryError);
    };
    const onPhoneNumberChange = (ev: any) => {
        updateField(ev.target.value, setPhoneNumber, setPhoneNumberError);
    };
    const onEmailChange = (ev: any) => {
        updateField(ev.target.value, setEmail, setEmailError);
    };
    const onBirthdayChange = (ev: any) => {
        updateField(ev.target.value, setBirthday);
    };
    const onCompanyChange = (ev: any) => {
        updateField(ev.target.value, setCompany);
    };
    const onMessageChange = (ev: any) =>{
        updateField(ev.target.value, setMessage);
    }
    const onAllergiesChange = (ev: any) =>{
        updateField(ev.target.value, setAllergies);
    }
    const onTermsCheckedChange = (ev: any, checked: boolean) => {
        setTermsChecked(checked);
        if (checked) {
            setTermsError(false);
        }
    };
    const onAllergiesTermsCheckedChange = (ev: any, checked: boolean) => {
        setAllergiesTermsChecked(checked);
        if (checked) {
            setAllergiesTermsError(false);
        }
    };
    const checkFormValidity = () => {
        setDisabled(true);
        let error = false;
        error = checkFieldValidity(firstName, setFirstNameError, error);
        error = checkFieldValidity(lastName, setLastNameError, error);
        error = checkFieldValidity(defaultCountry ? defaultCountry.code : "", setDefaultCountryError, error);
        error = checkFieldValidity(phoneNumber, setPhoneNumberError, error);
        if (!customFields.allow_blank_email) {
          error = checkFieldValidity(email, setEmailError, error);
        }
        if (!termsChecked) {
            setTermsError(true);
            error = true;
        }
        if (customFields.show_allergies_terms && !allergiesTermsChecked) {
            setAllergiesTermsError(true);
            error = true;
        }
        if (error) {
            setDisabled(false);
        } else {
            const contact_info: PendingBookingContact = {
                firstname: firstName,
                lastname: lastName,
                country_code: defaultCountry.code,
                mobile_phone: phoneNumber,
                email: email ? email : "",
                message: message ? message : "",
                allergies: allergies ? allergies : ""
            };
            if (birthday && birthday.length) {
                contact_info.birthday = birthday;
            }
            if (company && company.length) {
                contact_info.company = company;
            }
            pendingBookingStore.pendingBookingContact = contact_info

            updatePendingBookingRequest({contact_info}).subscribe((resp) => {
                if (hasPrepayment) {
                    history.push(buildRouteWithParams(config.routes.confirmationPayment));
                } else {
                    commitPendingBooking({}).subscribe((resp: Booking) => {
                        bookingStore.booking = resp
                        history.push(buildRouteWithParams(config.routes.confirmationEnd));
                    });
                }
            }, throwError);
        }
    };
    const checkFieldValidity = (value: string, setErrorCb: any, hasError: boolean) => {
        if (!value || !value.length) {
            setErrorCb(t("instabook.contacts.required_field_error"));
            return true;
        }
        return hasError;
    };
    const updateField = (value: string, messageCb: any, errorCb?: any) => {
        messageCb(value);
        if (value && value.length && errorCb) {
            errorCb(false);
        }
    };

    useEffect(() => {
        navigationStore.currentRoute = buildRouteWithParams(config.routes.confirmation);
    }, []);

    useEffect(() => {
        const localeUrl = (i18n.language !== "fr") ? "en" : "fr";
        setTerms(`${moduleSettings.getDisclaimerUrl()}&locale=${localeUrl}`);
        setPolicy(`${moduleSettings.getPrivacyPolicyUrl()}&locale=${localeUrl}`);
            return () => {
                getLanguageSwitcherService().unregisterListeners();
            };
    }, [i18n.language]);

    useEffect(() => {
        const defaultCountry = Countries.find((country: any) => {
            return country.code === i18n.language.toUpperCase();
          });
          if(defaultCountry){
            setDefaultCountry(defaultCountry)
          }
    }, [i18n.language])
  
    useEffect(() => {
        const errorDetails = errorMsg != null ? errorMsg["errorDetails"] : null
        if (errorDetails !== null){
            if (errorDetails.hasOwnProperty('contact_info')){
                const contactInfo = errorDetails["contact_info"]
                const fieldsInError = Object.keys(contactInfo)
                setPhoneNumberError(fieldsInError.includes("mobile_phone") ? t("instabook.errors.invalid_field") : "")
                setDefaultCountryError(fieldsInError.includes("country_code") ? t("instabook.errors.invalid_field") : "");
                setEmailError(fieldsInError.includes("email") ? t("instabook.errors.invalid_field") : "");
            }
        }
    }, [errorMsg, t]);

    getTagManagerService()?.pushTags("contact_info", {guests: selectionStore.guests});

    const bookingState = pendingBookingStore.getBookingState();
    let buttonText = "button.reserve";
    if (hasPrepayment || bookingState === BookingState.initialized) {
        buttonText = "button.ask_for_reservation";
    }
    if (bookingState === BookingState.waiting) {
        buttonText = "button.ask_for_waiting_list";
    }

    return (

        <ContactFormWrapper className={disabled ? "disabled" : "" }>
            {errorMsg && <ErrorMessage error={errorMsg["error"]} status={errorMsg["status"]} />}

        <Name>
            <CustomErrorField
                onChange={onFirstNameChange}
                className={firstNameError ? "error" : ""}
                required={true}
                fullWidth={true}
                label={t("instabook.contacts.firstname")}
                variant={"outlined"}
                helperText={firstNameError}
                defaultValue={firstName}
                />
                
        </Name>
        <Name>
            <CustomErrorField
                onChange={onLastNameChange}
                className={lastNameError ? "error" : ""}
                required={true}
                fullWidth={true}
                label={t("instabook.contacts.lastname")}
                variant={"outlined"}
                helperText={lastNameError}
                defaultValue={lastName}
                />
        </Name>
        <Prefix>
            <CountrySelect onCountryChange={onCountryChange} defaultValue={defaultCountry} countries={Countries}/>
        </Prefix>
        <PhoneNumber>
            <CustomErrorField
                onChange={onPhoneNumberChange}
                className={phoneNumberError ? "error" : ""}
                required={true}
                fullWidth={true}
                label={t("instabook.contacts.mobile_phone")}
                helperText={phoneNumberError}
                defaultValue={phoneNumber}
                variant={"outlined"}/>

        </PhoneNumber>
        {customFields.show_birthday ?
            <FullElement>
                <EmailWithBirthday>
                    <CustomErrorField
                            onChange={onEmailChange}
                            className={emailError ? "error" : ""}
                            helperText={emailError}
                            required={!customFields.allow_blank_email}
                            fullWidth={true}
                            label={t("instabook.contacts.email")}
                            defaultValue={email}
                            variant={"outlined"}/>
                </EmailWithBirthday>
                <Birthday>
                    <TextField
                        onChange={onBirthdayChange}
                        type="text"
                        defaultValue={birthday}
                        label={t("instabook.contacts.birthday")}
                        variant={"outlined"}
                    />
                </Birthday>
            </FullElement>
            :
            <FullElement>
                {!customFields.allow_blank_email ?
                    <CustomErrorField
                        onChange={onEmailChange}
                        className={emailError ? "error" : ""}
                        helperText={emailError}
                        required={true}
                        fullWidth={true}
                        defaultValue={email}
                        label={t("instabook.contacts.email")}
                        variant={"outlined"}/> :
                    <TextField
                        onChange={onEmailChange}
                        fullWidth={true}
                        defaultValue={email}
                        label={t("instabook.contacts.email")}
                        variant={"outlined"}/>}
            </FullElement>
        }
        {customFields.show_company_on_module ?
            <FullElement>
                <TextField
                    onChange={onCompanyChange}
                    fullWidth={true}
                    multiline={true}
                    label={t("instabook.contacts.company")}
                    rows="4"
                    variant="outlined"/>
            </FullElement>
            : ""
        }
        <FullElement>
            <TextField
                onChange={onMessageChange}
                fullWidth={true}
                multiline={true}
                rowsMax={4}
                label={t("instabook.contacts.message")}
                defaultValue={message}
                variant="outlined"/>
        </FullElement>
        <FullElement>
            <TextField
                onChange={onAllergiesChange}
                fullWidth={true}
                multiline={true}
                rowsMax={4}
                label={t("instabook.contacts.allergies")}
                defaultValue={allergies}
                variant="outlined"/>
        </FullElement>
        {customFields.show_allergies_terms &&
            <RadioWrapper>
                <FormControlStyledLabel
                    onChange={onAllergiesTermsCheckedChange}
                    className={allergiesTermsError ? "error" : ""}
                    control={<Checkbox name="allergyTerms"/>}
                    label={t("instabook.contacts.accept_allergies_terms")}
                />
        </RadioWrapper> }
        <RadioWrapper>
            <FormControlStyledLabel
                onChange={onTermsCheckedChange}
                className={termsError ? "error" : ""}
                control={<Checkbox name="terms"/>}
                label={<span
                    dangerouslySetInnerHTML={setDangerouslyInnerHtml(t, "instabook.contacts.accept_terms", {
                        terms,
                        policy
                    })}/>}
            />
        </RadioWrapper>
        {customFields.accept_partner_spam ? <RadioWrapper>
            <FormControlStyledLabel
                control={<Checkbox name="show_tol_span"/>}
                label={<span
                    dangerouslySetInnerHTML={setDangerouslyInnerHtml(t, "instabook.contacts.accept_spam", {
                        partner
                    })}/>}
            />
        </RadioWrapper> : ""}
        <ContinueButton buttonTextKey={buttonText}
                        position={AcceptedPositions.center}
                        onClick={checkFormValidity}
                        disabled={disabled}/>
    </ContactFormWrapper>);
};
export const ConfirmationContactForm = memo(ContactForm);
