import React, {memo, useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import {config} from "../../../config";
import {SelectionGuests} from "../selection-guests/selection-guests";
import {SelectionCalendar} from "../selection-calendar/selection-calendar";
import {SelectionTime} from "../selection-time/selection-time";
import {moduleSettings} from "../../../stores/module-settings.store";
import {SelectionCommercials} from "../../commercials/selection-commercials/selection-commercials";
import {CommercialsRouter} from "../../commercials/commercials-router/commercials-router";
import styled from "styled-components";
import {buildRouteWithParams} from "../../../helpers/build-route-with-params";
import {GeneralMessage} from "../../general-message/general-message";
import { NavigationHistory } from "../../navigation-history/navigation-history";



const SelectionLayoutWrapper = styled.div`
  padding-bottom: 35px;
`;


const Selection = () => {
    const [firstStepTemplate, setFirstStepTemplate] = useState<JSX.Element>();
   
    const firstStepCommercialCompleted = () => {
        setFirstStepTemplate(<SelectionGuests/>);
    };
    useEffect(() => {
        if (moduleSettings.hasCommercialFirstStep()) {
            setFirstStepTemplate(<SelectionCommercials isFirstStep={true}
                                                       firstStepCommercialCompleted={firstStepCommercialCompleted}
                                                       commercials={moduleSettings.getFirstStepCommercials()}/>);
        } else {
            setFirstStepTemplate(<SelectionGuests isFirstStep={true}/>);
        }
    }, []);

    return <SelectionLayoutWrapper>
        <NavigationHistory/>
        <GeneralMessage/>
        <Switch>
            <Route exact={true} path={buildRouteWithParams(config.routes.selection)}>
                 {firstStepTemplate}
             </Route>
             <Route path={buildRouteWithParams(config.routes.selectionGuests)}>
                <SelectionGuests/>
            </Route>
             <Route path={buildRouteWithParams(config.routes.selectionCalendar)}>
                   <SelectionCalendar/>
            </Route>
            <Route path={buildRouteWithParams(config.routes.selectionTime)}>
                   <SelectionTime/>
            </Route>
            <Route
                path={buildRouteWithParams(config.routes.selectionCommercials) + "/:commercialType"}>
                <CommercialsRouter/>
            </Route>
        </Switch>
    </SelectionLayoutWrapper>;
};
export const SelectionLayout = memo(Selection);
