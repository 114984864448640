import {getRequestParamsService} from "../services/request-params.service";
import {config} from "../config";

export const buildRouteWithParams = (route: string): string => {
    const requestParamsService = getRequestParamsService();
    const {referralKey, restaurantId, version} = requestParamsService.getParams();
    let baseUrl = version ? `${config.routes.base}/${version}` : config.routes.base;
    if (restaurantId) {
        return `${baseUrl}/${restaurantId}/${referralKey}/${route}`;
    } else {
        return `${baseUrl}/${referralKey}/${route}`;
    }
};
