import React, {memo, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {ThemeManager} from "../../theme-manager/theme-manager";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import { moduleSettings } from "../../stores/module-settings.store";

interface IStyle {
  isOpen: boolean;
}

const activeTheme = ThemeManager.getTheme();

const ChangeLanguage = styled.div`
  width: 100%;
  background-color: ${activeTheme.backgroundColor};
  color: ${activeTheme.textColor};
  position: relative;
  display: inline-block;
  border-radius: 4px;

  @media screen and (min-width: 769px) {
    &:hover {
      .languages {
        display: block;
      }
    }
  }
`;

const AvailableLanguagesWrapper = styled.div<IStyle>`
  position: absolute;
  z-index: 2;
  left: 0;
  width: 100%;
  display: none;
  background-color: ${activeTheme.backgroundColor};
  border: 1px solid black;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: ${props => props.isOpen ? "block" : "none" };
  }
`;

const Language = styled.div`
  padding: 4px;
  text-transform: capitalize;
`;

const CurrentLanguage = styled(Language)`
  position: relative;
`;

const ExpandMoreWrapper = styled(ExpandMoreOutlinedIcon)`
  position: absolute;
  right: 2px;
  top: 55%;
  transform: translateY(-50%);
`;

const AvailableLanguage = styled(Language)`
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  &:hover {
    background-color: ${activeTheme.headerBackgroundColor};
    color: white;
  }
`;

const Switcher = () => {
    const {i18n} = useTranslation();   
    const [isOpen, setIsOpen] = useState(false);

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language)
    };

    const onCurrentLanguageClick = (ev: any) => {
      ev.preventDefault();
      setIsOpen(!isOpen);
  };
    const onLanguageClick = (ev: any) => {
        const lang = ev.target.getAttribute("data-lang");
        changeLanguage(lang);
        setIsOpen(false);
    };
    const getAvailableLanguages = () => {
        return moduleSettings.getAvailableLocales()
            .filter((language: string) => language !== i18n.language)
            .map((language: string) => {
                return <AvailableLanguage
                    onClick={onLanguageClick}
                    key={language}
                    data-lang={language}>{language}</AvailableLanguage>;
            });
    };
    const availableLanguagesDropdown = getAvailableLanguages();
    return <ChangeLanguage>
        <CurrentLanguage
            key={i18n.language}
            data-lang={i18n.language}
            onClick={onCurrentLanguageClick}>
              <div className={"languageName"}>{i18n.language}</div>
              <ExpandMoreWrapper/>
        </CurrentLanguage>
        <AvailableLanguagesWrapper className="languages" isOpen={isOpen}>
            {availableLanguagesDropdown}
        </AvailableLanguagesWrapper>
    </ChangeLanguage>;
};
export const LanguageSwitcher = memo(Switcher);
