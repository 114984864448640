import React, { memo } from "react";
import styled from "styled-components";
import PauseIcon from "@material-ui/icons/Pause";
import {ThemeManager} from "../../theme-manager/theme-manager";

const activeTheme = ThemeManager.getTheme();

interface IProps {
  icon: string;
}
const RoundedIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${activeTheme.secondaryColor};
  color: white;
  margin-left: 24px;
  margin-bottom: 24px;

  svg {
    width: 10px;
    height: 10px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 12px;
    margin-top: -12px;
  }
`;

const renderIcon = (icon: string) => {
  switch(icon) {
    case "PauseIcon":
        return <PauseIcon />;
  }
}

const RoundedIconDef = (props: IProps) => {
  return <RoundedIconWrapper>
    {renderIcon(props.icon)}
  </RoundedIconWrapper>
};

export const RoundedIcon = memo(RoundedIconDef);