import React, {memo} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { config } from "../../config";

const Img = styled.img`
    width: 180px;
`;

const AppLogo = () => {
    const {i18n} = useTranslation();
    const link = (i18n.language !== "fr") ? 'https://www.guestonline.io/en/' : 'https://www.guestonline.io/'
    
    return <a target="_blank" rel="noopener noreferrer" href={link}>
        <Img src={config.baseImageUrl+'poweredByGuestonline.png'} alt=""/>
    </a>;
};
export const Logo = memo(AppLogo);
