import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LOCALE_EN from "./locales/en.json";
import LOCALE_RO from "./locales/ro.json";
import LOCALE_FR from "./locales/fr.json";

const resources = {
    en: {
        translation: LOCALE_EN
    },
    ro: {
        translation: LOCALE_RO
    },
    fr: {
        translation: LOCALE_FR
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "fr",
        supportedLngs: ["en", "ro", "fr"],
        fallbackLng: "en",
        load: "all",
        interpolation: {
            escapeValue: false // react already safes from xss
        },

    });

export default i18n;
