import React, {memo, useState, useEffect} from "react";
import styled from "styled-components";
import {ThemeManager} from "../../../theme-manager/theme-manager";
import {TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {AcceptedPositions, ContinueButton} from "../../continue-button/continue-button";
import {inviteFriends} from "../../../requests/booking.requests";
import { useErrorHandler } from "react-error-boundary";

const activeTheme = ThemeManager.getTheme();

const ShareReservationWrapper = styled.div`
  color: ${activeTheme.disabledColor};
  margin-bottom: 36px;
`;

const FullElement = styled.div`
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 12px;
`;

const CustomErrorField = styled(TextField)`
  &.error {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${activeTheme.error};
    }

    .MuiOutlinedInput-root:not(.Mui-focused) {
      color: ${activeTheme.error};
    }
  }
`;

const MessageText = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${activeTheme.reservationSuccess};
  color: white;
  padding: 20px 60px;
  border-radius: 5px;
  margin-bottom: 36px;
`;

const Share = () => {
    const {t} = useTranslation();
    const [message, setMessage] = useState<string>("");
    const [messageError, setMessageError] = useState<boolean>(false);
    const [emails, setEmails] = useState<string>("");
    const [emailsError, setEmailsError] = useState<boolean>(false);
    const [formattedEmails, setFormattedEmails] = useState<string[]>([]);
    const [isShared, setIsShared] = useState<boolean>(false);

    const throwError = useErrorHandler();

    useEffect(() => {
      if (formattedEmails.length > 0) {
          const subscription = inviteFriends(message!, formattedEmails).subscribe((resp: any) => {
            setIsShared(true);
          }, throwError);
          return () => {
            subscription.unsubscribe();
          };
      }
    }, [formattedEmails, throwError, message]);

    const shareReservation = () => {
        let error = false;
        if (!message?.length) {
            error = true;
            setMessageError(true);
        }
        if (!emails?.length) {
            error = true;
            setEmailsError(true);
        }
        if (!error) {
            setFormattedEmails((emails.split(",")).map((formattedEmail: string) => formattedEmail.trim()));
        }
    };

    const messageChanged = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newMessage = ev.target.value;
        updateField(newMessage, setMessage, setMessageError);
    };

    const emailChanged = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newEmails = ev.target.value;
        updateField(newEmails, setEmails, setEmailsError);
    };

    const updateField = (value: string,
                          messageCb: (value: React.SetStateAction<string>) => void,
                          errorCb: (value: React.SetStateAction<boolean>) => void) => {
        messageCb(value);
        if (value.length) {
            errorCb(false);
        }
    }

    const contentAfterSharing = <MessageText>{t("instabook.bookings.friend_sharing.success")}</MessageText>;

    const contentForSharing = (<div>
        <FullElement>
            <CustomErrorField fullWidth={true}
                              multiline={true}
                              className={messageError ? "error" : ""}
                              label={t("instabook.booking_sharing.to_friends")}
                              rows="4"
                              variant="outlined"
                              onChange={messageChanged}/>
        </FullElement>
        <FullElement>
            <CustomErrorField fullWidth={true}
                              multiline={true}
                              className={emailsError ? "error" : ""}
                              label={t("instabook.booking_sharing.send_to_friends")}
                              rows="1"
                              variant="outlined"
                              onChange={emailChanged}/>
        </FullElement>
            <ContinueButton onClick={shareReservation}
                            buttonTextKey={t("instabook.booking_sharing.invite")}
                            position={AcceptedPositions.left}/>
      </div>);

    return <ShareReservationWrapper>
       {isShared ? contentAfterSharing : contentForSharing}
    </ShareReservationWrapper>;
};

export const ShareReservation = memo(Share);
