import {InitialPendingBookingRequest} from "../types";
import {selectionStore} from "../stores/selection.store";
import {moduleSettings} from "../stores/module-settings.store";
import i18n from "../i18n";

class CreatePendingBookingService {
    createInitialPendingBooking(): InitialPendingBookingRequest {
        const area_id = selectionStore.area;
        const date = selectionStore.date.format("YYYY-MM-DD");
        const hour = selectionStore.time;
        const persons = selectionStore.guests;
        const user_locale = i18n.language;
        const restaurant_id = +moduleSettings.getRestaurantId();
        const pendingBooking: InitialPendingBookingRequest = {
            date,
            hour,
            persons,
            restaurant_id,
            user_locale,

        };
        if (area_id && "all" !== area_id) {
            pendingBooking.area_id = area_id;
        }
        return pendingBooking;
    }
}

let createPendingBooking: CreatePendingBookingService;

export const getCreatePendingBookingService = (): CreatePendingBookingService => {
    if (!createPendingBooking) {
        createPendingBooking = new CreatePendingBookingService();
    }
    return createPendingBooking;
};
