import {Commercial, CommercialsObject} from "../types";

class CommercialsStore {
    private _events: Array<any> = [];
    private _offers: Array<any> = [];
    private _menus: Array<any> = [];

    public mapCommercialsToSpecific(commercials: Array<Commercial>) {
        this._events = [];
        this._offers = [];
        this._menus = [];
        commercials.forEach((commercial: Commercial) => {
            switch (commercial.object) {
                case CommercialsObject.menu: {
                    this._menus.push(commercial);
                    break;
                }
                case CommercialsObject.event: {
                    this._events.push(commercial);
                    break;
                }
                case CommercialsObject.offer: {
                    this._offers.push(commercial);
                    break;
                }
                default:
                    break;
            }
        });
    }

    public get menus(): Array<any> {
        return this._menus;
    }

    public set menus(menus: Array<any>) {
        this._menus = menus;
    }

    public get offers(): Array<any> {
        return this._offers;
    }

    public set offers(offers: Array<any>) {
        this._offers = offers;
    }

    public get events(): Array<any> {
        return this._events;
    }

    public set events(events: Array<any>) {
        this._events = events;
    }
}

export const commercialsStore = new CommercialsStore();
