import React, {memo} from "react";
import styled, {keyframes} from "styled-components";
import {ThemeManager} from "../../theme-manager/theme-manager";

const activeTheme = ThemeManager.getTheme();

interface IProps {
  size?: string;
}

interface ILoaderStyle {
  size?: string;
}

const spinner = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoaderSpinner = styled.div<ILoaderStyle>`
	position: absolute;
	border: ${props => props.size === "large" ? "20px" : "10px" } solid #f3f3f3;
	border-top: ${props => props.size === "large" ? "20px" : "10px"}  solid ${activeTheme.primaryColor};
	border-radius: 50%;
	width: ${props => props.size === "large" ? "200px" : "100px"};
	height: ${props => props.size === "large" ? "200px" : "100px"};
	animation: ${spinner} 2s linear infinite;
`;

const LoaderSpinnerContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const LoaderDef = (props: IProps) => {
    return <LoaderSpinnerContainer>
        <LoaderSpinner size={props.size}/>
    </LoaderSpinnerContainer>;
};
export const Loader = memo(LoaderDef);