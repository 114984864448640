import { GolReducer } from "../types";

class GolEventListener{
    private _componentName: string;
    private _reducers: Array<GolReducer>;

    constructor(component:string, observeProps: Array<string>, reducer: () => void){
        this._componentName = component;
        this._reducers = [
            { 
                observeProps: observeProps, 
                reducer: reducer
            }
        ];
    }

    addReducer(observeProps: Array<string>, reducer: () => void){
        this._reducers.push({ observeProps: observeProps, reducer: reducer})
    }

    set reducers(newReducers: Array<GolReducer>){
        this._reducers = newReducers;
    }

    get reducers():Array<GolReducer>{
        return this._reducers;
    }

    get componentName():string{
        return this._componentName;
    }

   
    public callListeners = (observeProp:string) => {
        return this.reducersForObserver(observeProp).forEach((golReducer) => {
            golReducer.reducer();
        });
    }

    public reducersForObserver(observeProp:string):Array<GolReducer>{
        return this.reducers.filter((reducer) => reducer.observeProps.includes(observeProp));
    }
}
class SelectionStoreChangesService {
    public listeners: Array<GolEventListener> = [];

    public registerListener = (componentName:string, observeProps:Array<string>, actionToPerform: () => void) => {
        this.listeners.push(
            new GolEventListener(componentName, observeProps, actionToPerform)
        );
    };

    public unregisterListener = (component:string) => {
        this.listeners = this.listeners.filter( (listener) => listener.componentName !== component);
    };

    public callListeners = (observeProps:string) => {
        this.listeners.forEach((listener) => listener.callListeners(observeProps));
    }
}

let selectionStoreChangesService: SelectionStoreChangesService;

export const getSelectionStoreChangesService = (): SelectionStoreChangesService => {
    if (!selectionStoreChangesService) {
        selectionStoreChangesService = new SelectionStoreChangesService();
    }
    return selectionStoreChangesService;
};
