import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import { TimeSectionStyle, TimeSectionTitle } from "../time-section/time-section";
 

interface IProps {
    sectionTitleKey: string;
    noHourReason: string;
}

export const NoHourReasonStyle = styled.div`
    display: inline-block;
    text-align: left;
    color: "#f57c00";
    position: relative;
    text-decoration: none;
    padding: 6px 0;
    margin-right: 15px;
    margin-bottom: 35px;
    height: 30px;
    width: 100%;
`;


const NoTime = (props: IProps) => {
    const {t} = useTranslation();

    return <TimeSectionStyle>
        <TimeSectionTitle>{t("instabook.periods."+props.sectionTitleKey)}</TimeSectionTitle>
            <NoHourReasonStyle
                dangerouslySetInnerHTML={{ __html: props.noHourReason }} />
        </TimeSectionStyle>;
};
export const NoTimeSection = memo(NoTime);
