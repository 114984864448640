import {getModuleSettings} from "../requests/settings.request";
import {
    Area,
    AvailableCommercials,
    CommercialKeys,
    CustomContactFormFields,
    GuestsCapacity,
    ModuleSettings,
    SelectionCommercial
} from "../types";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {selectionStore} from "./selection.store";
import { config } from "../config";
import {getRequestParamsService} from "../services/request-params.service";
import moment from "moment";
import { getLocalStorageService } from "../services/local-storage.service";

class ModuleSettingsStore {
    public settings: ModuleSettings = {} as ModuleSettings;
    public initialBookingCreated: boolean = false;

    public getAvailableLocales(): Array<string> {
        return this.settings.available_locales;
    }

    public getDefaultLocale(): string {
        return this.settings.restaurant?.locale ?? "en";
    }

    public getRestaurantName(): string {
        return this.settings.restaurant?.name ?? "";
    }

    public getRestaurantId(): string {
        return String(this.settings.restaurant?.id);
    }

    public getRestaurantImageUrl(): string {
        return config.baseImageUrl + ('sidePicture/7232.png')
    }

    public getRestaurantLogoUrl(): string {
        return config.baseImageUrl + ('logo/7232.png')
    }

    public getReferralKey(): string {
        return this.settings.referral_key;
    }

    public getCapacity(): GuestsCapacity {
        if (this.hasCommercialFirstStep()) {
            if (selectionStore.firstStepCommercial.selected) {
                return {
                    minGuests: selectionStore.commercials[0].minimum_dishes,
                    maxGuests: selectionStore.commercials[0].maximum_dishes,
                };
            }
        }
        return {
            minGuests: 1,
            maxGuests: this.settings.restaurant?.capacity ?? 0
        };
    }

    public getDisclaimerUrl(): string {
        return this.settings.terms?.disclaimer_url ?? "";
    }

    public getPrepaymentDisclaimerUrl(): string {
        return this.settings.terms?.prepayment_disclaimer_url ?? "";
    }

    public getPrivacyPolicyUrl(): string {
        return this.settings.terms?.privacy_policy_url ?? "";
    }

    public getContactFormCustomFields(): CustomContactFormFields {
        return {
            accept_partner_spam: !this.settings.accept_partner_spam,
            allow_blank_email: this.settings.allow_blank_email,
            show_birthday: this.settings.show_birthday,
            show_company_on_module: this.settings.show_company_on_module,
            show_tol_span: this.settings.show_tol_span,
            show_allergies_terms: this.settings.show_allergies_terms_on_module,
            partner_name: this.settings.partner_display_name
        };
    }

    public hasCommercialFirstStep(): boolean {
        return this.hasMenusFirstStep() ||
            this.hasEventsFirstStep() ||
            this.hasOffersFirstStep();
    }

    public hasCommercialTypeFirstStep(commercialType: string): boolean {
        switch (commercialType) {
            case AvailableCommercials.offers:
                return this.hasOffersFirstStep();
            case AvailableCommercials.menus:
                return this.hasMenusFirstStep();
            default:
                return false;
        }
    }

    public getFirstStepCommercials(): SelectionCommercial {
        if (this.hasMenusFirstStep()) {
            return {
                type: AvailableCommercials.menus,
                commercials: this.firstStepMenus,
                commercialsKey: CommercialKeys.menus
            };
        }
        return {
            type: AvailableCommercials.offers,
            commercials: this.firstStepOffers,
            commercialsKey: CommercialKeys.offers
        };
    }

    public hasMenusFirstStep(): boolean {
        return !!this.settings.restaurant?.menus?.length;
    }

    public get online_booking_enabled(): boolean {
        return this.settings.restaurant?.online_booking_enabled;
    }

    public get firstStepMenus(): Array<any> {
        return this.settings.restaurant!.menus;
    }

    public hasEventsFirstStep(): boolean {
        return !!this.settings.restaurant?.events?.length;
    }

    public get firstStepEvents(): Array<any> {
        return this.settings.restaurant!.events;
    }

    public hasOffersFirstStep(): boolean {
        return !!this.settings.restaurant?.offers?.length;
    }

    public get firstStepOffers(): Array<any> {
        return this.settings.restaurant!.offers;
    }

    public get restaurantImageUrl(): string {
        return this.settings.side_picture_url;
    }

    public get restaurantLogoUrl(): string {
        return this.settings.logo_restaurant_url;
    }

    public get areas(): Array<Area> {
        return this.settings.restaurant!.areas;
    }

    public get periods(): Array<string> {
        return this.settings.restaurant!.periods;
    }

    public get last_booking_date(): string {
        return this.settings.restaurant!.last_booking_date;
    }

    public firstStepCommercialCompleted(isSelected: boolean) {
        selectionStore.setFirstStepCommercial(true, isSelected);
    }

    public getGeneralInfoMessage(lang: string): string | null {
        const note = this.settings.restaurant.bookings_detail?.note ?? null;
        for (let key in note) {
            if (note.hasOwnProperty(key)) {
                if(key === lang) {
                    return note[lang];
                }
            }
        }
        return null;
    }

    public initModuleSettings(): Observable<any> {
        const params = getRequestParamsService().getParams();
        return getModuleSettings(params.restaurantId, params.bookingId, params.bookingToken).pipe(
            tap((response: ModuleSettings) => {
                this.settings = response;
                getLocalStorageService().initLocalLanguage(this.getDefaultLocale());
                getRequestParamsService().restaurantId = String(this.settings.restaurant.id);
                getRequestParamsService().referralKey = this.settings.referral_key;
                if (this.settings.booking_date && this.settings.booked_persons_number) {
                    selectionStore.guests = this.settings.booked_persons_number;
                    selectionStore.calendarDate = moment(this.settings.booking_date, 'YYYY-MM-DD');
                }
                selectionStore.createSteps();
            })
        );
    }
}

export const moduleSettings = new ModuleSettingsStore();
