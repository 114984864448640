import React, {memo} from "react";
import styled from "styled-components";
import PaymentIcon from "@material-ui/icons/Payment";
import {ThemeManager} from "../../../theme-manager/theme-manager";
import PauseIcon from "@material-ui/icons/Pause";
import FlashOnIcon from "@material-ui/icons/FlashOn";

const activeTheme = ThemeManager.getTheme();

export interface ITimeSlotIcons {
    hasWaitingList?: boolean;
    hasOffer?: boolean;
    hasPrepayment?: boolean;
}

const SlotTimeIcons = styled.div`
  display: flex;
  position: absolute;
  height: 14px;
  top: -6px;
  right: 0;
`;

const RoundedIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: ${activeTheme.disabledColor};
  color: white;
  margin-left: 5px;

  &.colored {
    background-color: ${activeTheme.secondaryColor};
  }

  svg {
    width: 10px;
    height: 10px;
  }
`;

const SlotIcons = (props: { icons: ITimeSlotIcons }) => {
    return <SlotTimeIcons>
        {props.icons.hasWaitingList ?
          <RoundedIcon className={"colored"}>
              <PauseIcon/>
          </RoundedIcon> : ""}
        {props.icons.hasOffer ?
            <RoundedIcon>
                <FlashOnIcon/>
            </RoundedIcon> : ""}
        {props.icons.hasPrepayment ?
            <RoundedIcon>
                <PaymentIcon/>
            </RoundedIcon> : ""}
    </SlotTimeIcons>;
};
export const TimeSlotIcons = memo(SlotIcons);
