import React, {memo, useEffect, useState} from "react";
import {Commercial, PendingBooking} from "../../types";
import {selectionStore} from "../../stores/selection.store";
import {navigationStore} from "../../stores/navigation.store";
import {CommercialsMenuItem} from "./menu/commercials-menu-item";
import {CommercialsOfferItem} from "./offer/commercials-offer-item";
import {CommercialsEventItem} from "./event/commercials-event-item";
import {addMenuToPendingBooking, updatePendingBookingRequest} from "../../requests/pending-booking.requests";
import {pendingBookingStore} from "../../stores/pending-booking.store";
import { useErrorHandler } from "react-error-boundary";

interface ICommercialsItemProps {
    commercial: Commercial
    commercialSelected: () => void;
    isFirstStep?: boolean
}

const Item = (props: ICommercialsItemProps) => {
    const throwError = useErrorHandler();
    const [selectedOffer, setSelectedOffer] = useState(null)
    let currentMenuCount = 0;
    if (selectionStore.menuCount) {
        currentMenuCount = selectionStore.menuCount;
    }

    useEffect(() => {
        if (selectedOffer !== null){
            const subscription = updatePendingBookingRequest({selected_offer: props.commercial.id}).subscribe((resp) => {
                navigationStore.addOfferToHistory(props.commercial);
                pendingBookingStore.pendingBooking = resp;
                props.commercialSelected();
            }, throwError);
            return () => {
                subscription.unsubscribe();
            }
        }
    }, [selectedOffer])
    const [menuCount, setMenuCount] = useState(currentMenuCount);
    const commercialClicked = () => {
        selectionStore.addCommercial(props.commercial);
        if(props.commercial.object === "offer"){
            setSelectedOffer(props.commercial)
        }else{
            if (props.commercial.object === "menu") {
                props.commercialSelected();
                navigationStore.addMenuToHistory(props.commercial, menuCount);
                selectionStore.menuCount = menuCount;
                addMenuToPendingBooking(props.commercial.id, menuCount).subscribe((resp: PendingBooking) => {
                    pendingBookingStore.pendingBooking = resp;
                });
            }
        }
    };
    const menuCountChanged = (newValue: number) => {
        setMenuCount(newValue);
    };
    let item: JSX.Element;
    switch (props.commercial.object) {
        case "event":
            item = <CommercialsEventItem commercial={props.commercial}/>;
            break;
        case "offer":
            item = <CommercialsOfferItem commercial={props.commercial}/>;
            break;
        default:
            item = <CommercialsMenuItem commercial={props.commercial}
                                        menuCountChanged={menuCountChanged}
                                        isFirstStep={props.isFirstStep}
                                        menuCount={menuCount}/>;
    }
    return <div onClick={commercialClicked}>
        {item}
    </div>;
};

export const CommercialsItem = memo(Item);
