import i18n from "../i18n";

const localStorageKeys = {
    preferredLanguage: "329491-hnbvch-sa7rf8-ans75j-3mc8hs-language",
    selection: "snhudo-sadnh2-3897hb-u9ochn-02879f-selection"
};

class LocalStorageService {
    public initLocalLanguage(defaultLocale: string) {
        i18n.changeLanguage(this.preferredLanguageCookie || defaultLocale);
    }

    public get preferredLanguageCookie(): string {
        return localStorage.getItem(localStorageKeys.preferredLanguage) || "";
    }

    public set preferredLanguageCookie(value: string) {
        localStorage.setItem(localStorageKeys.preferredLanguage, value);
    }

   
}

let localStorageService: LocalStorageService;

export const getLocalStorageService = (): LocalStorageService => {
    if (!localStorageService) {
        localStorageService = new LocalStorageService();
    }
    return localStorageService;
};
