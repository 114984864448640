import {InviteFriendsEndpoint} from "../endpoints/BookingEndpoint";
import {getHttpService} from "../services/http.service";
import {bookingStore} from "../stores/booking.store";

const httpService = getHttpService();

export const inviteFriends = (message: string, emails: Array<string>) => {
    const id = bookingStore.booking.id;
    const endpoint = new InviteFriendsEndpoint(id);
    return httpService.put(endpoint, {message, emails});
}
