import React, { memo } from "react";
import styled from "styled-components";
import { ThemeManager } from "../../theme-manager/theme-manager";
import { LanguageSwitcher } from "../language-switcher/language-switcher";
import { moduleSettings } from "../../stores/module-settings.store";
import { useTranslation } from "react-i18next";
import { HeaderStyle } from "../../types";

const activeTheme = ThemeManager.getTheme();

const HeaderWrapper = styled.div`
  height: ${activeTheme.headerHeight};
  width: 100%;
  background-color: ${activeTheme.headerStyle === HeaderStyle.text ? "white" : activeTheme.headerBackgroundColor};
  color: ${activeTheme.headerStyle === HeaderStyle.text ? activeTheme.headerBackgroundColor : activeTheme.invertFontColor};
  box-shadow: ${activeTheme.headerStyle === HeaderStyle.text ? "0 2px 4px rgba(0, 0, 0, 0.25)" : "none"};
  padding: 8px 20px;
  display: flex;
  align-items: center;

  @media screen and (min-width: 781px) {
    justify-content: center;
  }
`;

const LanguageSwitcherWrapper = styled.div`
  position: absolute;
  right: 20px;
  width: 45px;
`;

const Img = styled.img`
    max-height: 90%;
`;

const ReserveOnline = styled.div`
  font-size: 18px;
  line-height: 22px;
  max-width: calc(100% - 55px);

  span {
    font-weight: bold;
  };

  @media screen and (max-width: 780px) {
    font-size: 16px;
    line-height: 19px;
  };
`;

const HeaderDef = () => {
  const restaurantName = moduleSettings.getRestaurantName();
  const restaurantLogoUrl = moduleSettings.getRestaurantLogoUrl();
  const { t } = useTranslation();

  const content = restaurantLogoUrl ?  <Img src={restaurantLogoUrl} alt=""/> :
    <ReserveOnline>{t("instabook.button_txt")} <span>{restaurantName}</span></ReserveOnline>

  return <HeaderWrapper data-test="header" >
    {content}
    <LanguageSwitcherWrapper>
      <LanguageSwitcher />
    </LanguageSwitcherWrapper>
  </HeaderWrapper>;
};
export const Header = memo(HeaderDef);
