import React, {memo, useEffect} from "react";
import {ReservationStatus, StatusColors} from "./reservation-status";
import {BookingSummary} from "./booking-summary";
import {ShareReservation} from "./share-reservation";
// import {SaveReservation} from "./save-reservation";
import styled from "styled-components";
import {config} from "../../../config";
import {navigationStore} from "../../../stores/navigation.store";
import { moduleSettings } from "../../../stores/module-settings.store";
import {bookingStore} from "../../../stores/booking.store";
import {buildRouteWithParams} from "../../../helpers/build-route-with-params";
import {PendingBookingMessage} from "../../pending-booking-message/pending-booking-message";
import { BookingState } from "../../../types";

const ValidationWrapper = styled.div`
    margin-bottom: 50px;
`

const Validation = () => {

    const booking = bookingStore.booking;
    const canSharingFriend = moduleSettings.settings.show_sharing_friend_on_module && booking.booking_state === BookingState.validated

    useEffect(() => {
        navigationStore.currentRoute = buildRouteWithParams(config.routes.confirmationEnd);
    }, []);
    return <ValidationWrapper>
        {/* <GeneralMessage/> */}
        <PendingBookingMessage/>
        <BookingSummary booking={booking}/>
        <ReservationStatus color={StatusColors.green} booking={booking}/>
        {/* <SaveReservation/> */}
        {canSharingFriend && <ShareReservation/>}
    </ValidationWrapper>;
}

export const ConfirmationValidation = memo(Validation);
