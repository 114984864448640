import i18n from '../../i18n';
import { AxiosError } from "axios";
import { AccessApiErrorBody, AccessDeniedError, ApiError, ApiErrorResponse, ApiErrorBody, ApiErrorCode, ErrorType, GolBaseError } from "./error-types";
import { buildRouteWithParams } from '../../helpers/build-route-with-params';
import { config } from '../../config';
import { AvailableCommercials } from '../../types';

export class ApiErrorImpl implements ApiError, GolBaseError {
    response?: any;
    status: number;
    code: ApiErrorCode;
    data?: ApiErrorBody;

    constructor(error: AxiosError<ApiErrorResponse>) {
        this.response = error.response
        this.code = ApiErrorCode.unknown
        this.status = -1

        if (error.response) {
            this.status = error.response.status;
            this.data = error.response.data?.error;
            let errorCode = this.data?.code
            if (errorCode) {
                this.code = ApiErrorCode[errorCode as keyof typeof ApiErrorCode] ?? ApiErrorCode.unknown
            }
        }
    }

    getRedirectPath(){
        return buildRouteWithParams(config.routes.selectionGuests)
    }

    getErrorType(){
        return ErrorType.error;
    }

    getMessage() {
        return i18n.t(this.getMessagKey())
    }

    getMessagKey() {
        switch (this.code){
            case ApiErrorCode.restaurant_blank: case ApiErrorCode.restaurant_id_missing: case  ApiErrorCode.access_denied:{
                return `instabook.errors.connection_error`;
            }
            default:{
                return `instabook.errors.${this.code}`;
            }
        }
    }
}

export class ValidationErrorApiErrorImpl extends ApiErrorImpl {
     getMessagKey() {
        return `instabook.errors.invalid_data`;
    }

    getResourcesInError():Array<string>{
       
        if (this.data){
            return Object.keys(this.data) 
        }
        return []
    }

    getRedirectPath() {
        if ([ApiErrorCode.capture_failed, ApiErrorCode.payment_refused, ApiErrorCode.setup_intent_invalid, ApiErrorCode.stripe_customer_creation_failed].includes(this.code)){
            return buildRouteWithParams(config.routes.confirmationPayment);
        }else{
            let resources = this.getResourcesInError();
            if (resources.includes( "contact_info")){
                return buildRouteWithParams(config.routes.confirmation)
            }else if (resources.includes( "menus")){
                return buildRouteWithParams(config.routes.selectionCommercials)
            }
        }
        return buildRouteWithParams(config.routes.selectionCalendar)
    }

}
export class ResourceNotFoundApiErrorImpl extends ApiErrorImpl {
     getMessagKey() {
         switch(this.code){
            case ApiErrorCode.menu_unfound:{
                return "menu unfound";
            }
            case ApiErrorCode.runtime_commercial_unfound:{
                return "instabook.errors.runtime_commercial_unfound";
            }
            case ApiErrorCode.restaurant_blank:{
                return `instabook.errors.connection_error`;
            }
            default:{
                return `instabook.errors.session_expired`;
            }
         }
    }

    getRedirectPath(){
        if (ApiErrorCode.runtime_commercial_unfound === this.code){
            return buildRouteWithParams(config.routes.selectionCommercials) + `/${AvailableCommercials.offers}`

        }else{
            return super.getRedirectPath();
        }
    }
}

export class BadRequestApiErrorImpl extends ApiErrorImpl {
    constructor(error: AxiosError<ApiErrorResponse>) {
        super(error);
        this.code =  ApiErrorCode.bad_request;
    }

     getMessagKey() {
        return `instabook.errors.error`;
    }
}

export class AccessDeniedApiErrorImpl extends ApiErrorImpl implements AccessDeniedError {

    getMessage() {
        if (this.isPartnerNameNeeded()) {
            return i18n.t(this.getMessagKey(), { "partner_name": this.partnerName() })
        }
        return super.getMessage()
    }

    partnerName() {
        return (this.data as AccessApiErrorBody)?.partner_name ?? ""
    }

    isPartnerNameNeeded() {
        return (this.code === ApiErrorCode.access_denied ||
            this.code === ApiErrorCode.partnership_disabled ||
            this.code === ApiErrorCode.partnership_not_yet_created)
    }
}

export class RuntimeErrorImpl extends Error implements GolBaseError {
    constructor(){
        super()
        this.message = i18n.t("instabook.messages.booking_not_available_for_people_table_engine")
    }

    getRedirectPath(){
       return buildRouteWithParams(config.routes.selectionCalendar)
    }
    getMessage() {
        return this.message;
    }

    getErrorType(){
        return ErrorType.warning;
    }
}