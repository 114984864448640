import React, {memo} from "react";
import {CommercialTitle} from "../commercial-title";
import {CommercialText} from "../commercial-text";
import {Commercial} from "../../../types";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import styled from "styled-components";
import {ThemeManager} from "../../../theme-manager/theme-manager";

const activeTheme = ThemeManager.getTheme();

interface ICommercialsEventItemProps {
    commercial: Commercial
    isFirstStep?: boolean
}

const CommercialEvent = styled.div`
  background-color: ${activeTheme.informationTextBackgroundColor};
  border-radius: 5px;
  padding: 12px 24px;
  margin-bottom: 22px;
`;

const Item = (props: ICommercialsEventItemProps) => {
    return <CommercialEvent onClick={(ev) => {
        ev.stopPropagation();
    }}>
        <CommercialTitle title={props.commercial.name} icon={<FlashOnIcon/>}/>
        <CommercialText text={props.commercial.description}/>
    </CommercialEvent>;
};

export const CommercialsEventItem = memo(Item);
