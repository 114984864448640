import React, {memo} from "react";
import styled from "styled-components";

interface IPreviewMenuModal {
    closeModal: (ev: any) => void;
    title: string;
    text: string;
}

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .4);
  z-index: 2;
  cursor: default;
`;

const ModalContent = styled.div`
  max-width: 75%;
  max-height: 75%;
  background-color: #fff;
  position: relative;
  padding: 25px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const ModalClose = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ::before,
  ::after {
    position: absolute;
    content: " ";
    width: 70%;
    height: 1px;
    background-color: black;
  }

  ::before {
    transform: rotate(45deg);
  }

  ::after {
    transform: rotate(-45deg);
  }
`;

const ModalTitle = styled.div`
  flex: 0 0 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
`;

const PreviewMenuModal = (props: IPreviewMenuModal) => {
    const closeModal = (ev: any) => {
        ev.stopPropagation();
        props.closeModal(ev);
    };

    const modalClicked = (ev: any) => {
        props.closeModal(ev);
        ev.stopPropagation();
    }

    const modalContentClicked = (ev: any) => {
        ev.stopPropagation();
    }
    return <ModalContainer onClick={modalClicked}>
        <ModalContent onClick={modalContentClicked}>
            <ModalClose onClick={closeModal}/>
            <ModalTitle>
                {props.title}
            </ModalTitle>
            <div>
                {props.text}
            </div>
        </ModalContent>
    </ModalContainer>;
};

export const CommercialsMenuModal = memo(PreviewMenuModal);
