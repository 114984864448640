import React, {memo, useEffect} from "react";
import {
    CalendarFilters,
    CalendarFiltersPosition
} from "../../calendar/calendar-filters/calendar-filters";
import {CalendarLegend} from "../../calendar/calendar-legend/calendar-legend";
import {CalendarLayout} from "../../calendar/calendar";
import {navigationStore} from "../../../stores/navigation.store";
import {buildRouteWithParams} from "../../../helpers/build-route-with-params";
import {config} from "../../../config";

const Calendar = () => {
    useEffect(() => {
        navigationStore.currentRoute = buildRouteWithParams(config.routes.selectionCalendar);
    }, []);
    return <div>
        <CalendarFilters
            alignPosition={CalendarFiltersPosition.center}
        />
        <CalendarLayout/>
        <CalendarLegend/>
    </div>;
};
export const SelectionCalendar = memo(Calendar);
