import React, {memo} from "react";
import styled from "styled-components";
import {ThemeManager} from "../../../theme-manager/theme-manager";
import { RoundedIcon } from "../../rounded-icon/rounded-icon";

const activeTheme = ThemeManager.getTheme();

export enum borderStyles {
    dashed = "dashed",
    solid = "solid"
}

interface IProps {
    borderStyle?: borderStyles;
    text: string;
    explanation: string;
		unaivalable: boolean;
		icon?: string;
}

const LegendWrapper = styled.div`
	display: flex;
	margin: 5px;
`;
const LegendItem = styled.div`
	padding: 0 10px;
	display: flex;
	align-items: center;
`;
const LegendTitle = styled.div`
	text-align: center;
	font-size: 10px;
	color: ${activeTheme.textColorGray};
	padding-left: 8px;
`;
const LegendCircle = styled.div`
	display: flex;
	min-width: 30px;
	height: 30px;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	color: ${activeTheme.legendBorderGray};
	background-color: ${activeTheme.primaryColor}20;
	
	&.dashed {
		border: 1px dashed ${activeTheme.legendBorderGray};
	}
	
	&.solid {
		border: 1px solid ${activeTheme.legendBorderGray};
	}

	&.unaivalable {
		opacity: .4;
		background-color: transparent;
	}
`;

const Item = (props: IProps) => {
    return <LegendWrapper>
        <LegendItem>
						{props.icon && <LegendCircle>
							{props.text}
							<RoundedIcon icon="PauseIcon"/>
						</LegendCircle>}
            {props.borderStyle && <LegendCircle className={`${props.borderStyle} ${props.unaivalable ? "unaivalable" : ""}`}>{props.text}</LegendCircle>}
						{!props.icon && !props.borderStyle && <LegendCircle>{props.text}</LegendCircle>}
            <LegendTitle>{props.explanation}</LegendTitle>
        </LegendItem>
    </LegendWrapper>;
};
export const CalendarLegendItem = memo(Item);
