import React, { memo, useEffect, useState } from "react";
import { Header } from "../header/header";
import { MainContent } from "../main-content/main-content";
import styled from "styled-components";
import { useParams, useLocation } from "react-router-dom";
import { initRequestParamsService } from "../../services/request-params.service";
import { moduleSettings } from "../../stores/module-settings.store";
import { ApiErrorView } from "../errors/api-error-view";
import { Loader } from "../loader/loader";
import { Footer } from "../footer/footer";
import { ApiError } from "../../services/api-errors";
import { UrlParams } from "../../types";
import { useTranslation } from "react-i18next";


const MainLayoutWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
`;

const MainLayoutDef = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const bookingToken = searchParams.get('booking_token');
    const [moduleInitialized, setModuleInitialized] = useState(false);
    const [apiError, setApiError] = useState<ApiError>();
    const urlParams = useParams<UrlParams>();

    initRequestParamsService(urlParams, bookingToken);
    useEffect(() => {
        moduleSettings.initModuleSettings().subscribe(() => {
            setModuleInitialized(true);
            document.title = moduleSettings.getRestaurantName()+" - "+t("instabook.booking");
        }, (e: ApiError) => {
            setApiError(e);
        });
    }, []);

    if (!moduleInitialized && !apiError) {
        return <Loader />;
    };

    const content = apiError ? <ApiErrorView error={apiError} /> : <MainContent />;

    return <div>
        <MainLayoutWrapper>
            {moduleInitialized && <Header />}
            {content}
        </MainLayoutWrapper>
        <Footer />
    </div>
};

export const MainLayout = memo(MainLayoutDef);
