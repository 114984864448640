import React, {memo} from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {setDangerouslyInnerHtml} from "../../../helpers/set-inner-html";
import { moduleSettings } from "../../../stores/module-settings.store";
import {Booking, } from "../../../types";


interface SummaryProps {
    booking: Booking
}

const SummaryWrapper = styled.div`
    max-width: 500px;
    padding: 20px 30px;
    margin: 20px auto;
    text-align: center;
`

const Summary = (props: SummaryProps)  => {
    const {t, i18n} = useTranslation();
    const contact = props.booking.firstname + " " + props.booking.lastname;
    const count = props.booking.booked_persons_number;
    const date = new Date(props.booking.booking_date).toLocaleDateString(
        i18n.language, {
        day: "numeric",
        month: "long"
    });
    const hour = moment(props.booking.booking_time).format("HH") + "h" + moment(props.booking.booking_time).format("mm");
    const restaurant = moduleSettings.getRestaurantName();

    return <SummaryWrapper>
                <span dangerouslySetInnerHTML={setDangerouslyInnerHtml(
                    t,
                    count > 1  ? "instabook.bookings.summary_with_contact.other" : "instabook.bookings.summary_with_contact.one" ,
                    {
                        contact,
                        count,
                        date,
                        hour,
                        restaurant
                })}/>
            </SummaryWrapper>
}
export const BookingSummary = memo(Summary);
