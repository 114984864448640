import React, {memo, useEffect, useState} from "react";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import {ThemeManager} from "../../../theme-manager/theme-manager";
import styled from "styled-components";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {setDangerouslyInnerHtml} from "../../../helpers/set-inner-html";
import {moduleSettings} from "../../../stores/module-settings.store";
import {useTranslation} from "react-i18next";
import {AcceptedPositions, ContinueButton} from "../../continue-button/continue-button";
import {useHistory, useLocation} from "react-router-dom";
import {pendingBookingStore} from "../../../stores/pending-booking.store";
import {bookingStore} from "../../../stores/booking.store";
import {Booking, GolStripeCallbackResponse} from "../../../types";
import {commitPendingBooking} from "../../../requests/pending-booking.requests";
import {buildRouteWithParams} from "../../../helpers/build-route-with-params";
import {config} from "../../../config";
import { useErrorHandler} from 'react-error-boundary'
import { ErrorMessage } from "../../errors/message-error-view";
import { getLanguageSwitcherService } from "../../../services/language-switcher.service";

const activeTheme = ThemeManager.getTheme();

const CardInfoWrapper = styled.div`
  display: flex;

  &.disabled {
    pointer-events: none;
  }
`;

const BottomInfo = styled.div`
  color: ${activeTheme.disabledColor};
  font-size: 14px;
  line-height: 22px;
`;

const PrepaymentInfoWrapper = styled.div`
  width: 100%;
  padding: 30px 0;
`;

const TopInfo = styled(BottomInfo)`
  font-weight: bold;
`;

const CardInfoContainer = styled.div`
  flex: 0 0 50%;
  flex-wrap: wrap;
  min-width: 300px;
  display: flex;
  justify-content: space-between;
`;

const CardNumber = styled(CardNumberElement)`
  border: 1px solid ${activeTheme.legendBorderGray};
  padding: 9px;
  border-radius: 5px;
  flex: 0 0 100%;
  max-width: 100%;
  margin: 10px 0;
`;

const CardExpiry = styled(CardExpiryElement)`
  border: 1px solid ${activeTheme.legendBorderGray};
  padding: 9px;
  border-radius: 5px;
  flex: 0 0 25%;
`;

const CardCvc = styled(CardCvcElement)`
  border: 1px solid ${activeTheme.legendBorderGray};
  padding: 9px;
  border-radius: 5px;
  width: 70%;
`;

const RadioWrapper = styled.div`
  flex: 0 0 100%;

  &.disabled {
    pointer-events: none;
  }
`;

const FormControlStyledLabel = styled(FormControlLabel)`
  a {
    color: ${activeTheme.primaryColor};
  }

  &.error {
    .MuiCheckbox-root {
      color: ${activeTheme.error};
    }
  }
`;

const Checkout = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const [error, setError] = useState(useLocation().state);
    const [stripeError, setStripeError] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [clientSecret, setClientSecret] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const terms = moduleSettings.getDisclaimerUrl();
    const policy = moduleSettings.getPrivacyPolicyUrl();
    const [termsChecked, setTermsChecked] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [prepaymentType, setPrepaymentType] = useState("");
    const [prepaymentInfo, setPrepaymentInfo] = useState(pendingBookingStore.getPendingBookingPrepaymentInfo());
    const [stripeToken, setStripeToken] = useState(null);
    const [canCommit, setCanCommit] = useState(false);
    const throwError = useErrorHandler();

    const onTermsCheckedChange = (ev: any, checked: boolean) => {
        setTermsChecked(checked);
        if (checked) {
            setTermsError(false);
        }
    };
    useEffect(() => {
        const prepayment = pendingBookingStore.getPendingBookingPrepayment();
        setClientSecret(prepayment.client_secret);
        setPrepaymentType(prepayment.payment_kind)
    }, []);
    useEffect(() => {
      getLanguageSwitcherService().registerListener(() => {
        setPrepaymentInfo(pendingBookingStore.getPendingBookingPrepaymentInfo());
       });
       return () => {
           getLanguageSwitcherService().unregisterListeners(); 
       };
   }, []);
    useEffect(() => {
      if (canCommit){
        commitPendingBooking({stripe_token: stripeToken}).subscribe((resp: Booking) => {
          bookingStore.booking = resp
          history.push(buildRouteWithParams(config.routes.confirmationEnd));
        }, throwError);
      }
    
    }, [canCommit, throwError]);

    useEffect(() => {
      if(error){
        setDisabled(false)
      }
    }, [error]);


    const handleChange = async (event: any) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        if(event.error){
          setCanCommit(false)
          let updatedErrors =  { ...stripeError, [event.elementType] : event.error.message}
          setStripeError(updatedErrors)
          
        }else{
          let updatedErrors = {...stripeError}
          delete updatedErrors[event.elementType]
          setStripeError(updatedErrors);
        }
        
    };

    const validatePayment = (ev: any) => {
      setCanCommit(false)
      if (Object.keys(stripeError).length > 0 || termsError){    
        return;
      }
      if(!termsChecked){
        setTermsError(true);
        return;
      }
   
      handleSubmit(ev);
        // history.push(buildRouteWithParams(config.routes.confirmationEnd));
    };

    const handleDeposit = async () : Promise<GolStripeCallbackResponse> =>{
      const {error, setupIntent} = await stripe!.confirmCardSetup(clientSecret, {
        payment_method: {
            card: elements!.getElement(CardNumberElement)!
        }
      }) ;

      return {
        stripeError: error,
        stripeToken: setupIntent ? setupIntent.id : null
      }
    };

    const handleDownPayment = async () : Promise<GolStripeCallbackResponse> =>{
      
      const {error, paymentIntent} = await stripe!.confirmCardPayment(clientSecret, {
        payment_method: {
            card: elements!.getElement(CardNumberElement)!
        }
      }) ;
      return {
        stripeToken: paymentIntent ? paymentIntent.id : null, 
        stripeError: error
      }
      
    };

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();

        if (!stripe || !elements) {
          setError({"error": "stripe or element not set"})
            return;
        }

        setDisabled(true);
        const {stripeError, stripeToken} = ("guarantee" === prepaymentType) ? await handleDeposit() : await handleDownPayment()
        setStripeToken(stripeToken ? stripeToken : null)
        setError(stripeError ? {"error": `${stripeError!.message}`} : null)
        setCanCommit(stripeToken !== null);
    };

    return (
            <form>
                <PrepaymentInfoWrapper>
                  {prepaymentInfo}
                </PrepaymentInfoWrapper>
                {error ? 
                  <ErrorMessage error={error["error"]} status={"error"}/> 
                    : Object.keys(stripeError).length > 0 ?  <ErrorMessage error={Object.values(stripeError).join("<br/>")} status={"error"}/>
                    : termsError && <ErrorMessage error={t(`instabook.prepayment.accept_terms_unchecked`)} status={"error"}/> 
                }

                  <TopInfo>{t("instabook.prepayment.payment_top")}</TopInfo>
                  <CardInfoWrapper className={disabled ? "disabled" : ""}>
                      <CardInfoContainer>
                          <CardNumber onChange={handleChange}/>
                          <CardExpiry onChange={handleChange}/>
                          <CardCvc onChange={handleChange}/>
                      </CardInfoContainer>
                  </CardInfoWrapper>
                  <BottomInfo>{t("instabook.prepayment.payment_bottom")}</BottomInfo>
                  {/* Show any error that happens when processing the payment */}

                  <RadioWrapper className={disabled ? "disabled" : ""}>
                      <FormControlStyledLabel
                          onChange={onTermsCheckedChange}
                          className={termsError ? "error" : ""}
                          control={<Checkbox name="terms"/>}
                          label={<span
                              dangerouslySetInnerHTML={setDangerouslyInnerHtml(t, "instabook.prepayment.terms_and_conditions", {
                                  terms,
                                  policy
                              })}/>}
                      />
                  </RadioWrapper>
                  <ContinueButton
                    id="submit"
                    onClick={validatePayment}
                    buttonTextKey={"button.confirm"}
                    position={AcceptedPositions.center}
                    disabled={disabled}/>
              </form>
    );
};
export const CheckoutForm = memo(Checkout);
