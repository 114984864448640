import React, {memo, useEffect} from "react";
import {Redirect} from "react-router-dom";
import {DetailsCoupon} from "../details-coupon/details-coupon";
import {config} from "../../../config";
import {navigationStore} from "../../../stores/navigation.store";
import {pendingBookingStore} from "../../../stores/pending-booking.store";
import {buildRouteWithParams} from "../../../helpers/build-route-with-params";
import {PendingBookingMessage} from "../../pending-booking-message/pending-booking-message";
import { NavigationHistory } from "../../navigation-history/navigation-history";

const Details = () => {
    const isVoucherEnabled = pendingBookingStore.getIsVoucherEnabled();

    useEffect(() => {
        navigationStore.currentRoute = buildRouteWithParams(config.routes.details);
    }, []);

    return <div>
        <NavigationHistory/>
        <PendingBookingMessage/>
        {isVoucherEnabled ? <DetailsCoupon/> : <Redirect to={buildRouteWithParams(config.routes.confirmation)}/>}
    </div>;
};
export const DetailsLayout = memo(Details);
